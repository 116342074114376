import { SearchIcon } from "@chakra-ui/icons";
import {
  Menu,
  MenuButton,
  Input,
  MenuList,
  MenuItem,
  InputGroup,
  InputLeftElement,
  useDisclosure,
  forwardRef,
  MenuButtonProps,
} from "@chakra-ui/react";
import React, { useState } from "react";

type SearchInputProps = {
  value: string;
  onChange: (v: string) => void;
};

const SearchInput = forwardRef<MenuButtonProps & SearchInputProps, "div">(
  ({ value, onChange }, ref) => (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="gray.500" />
      </InputLeftElement>
      <Input
        ref={ref}
        placeholder="Znajdź artykuł"
        _placeholder={{ fontFamily: "Cardo, sans-serif", color: "gray.500" }}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </InputGroup>
  )
);

export const SearchBar = () => {
  const [value, setValue] = useState("");

  return (
    <Menu isOpen={!!value} matchWidth flip={false}>
      <MenuButton
        w="full"
        as={SearchInput}
        value={value}
        // @ts-ignore
        onChange={(v) => setValue(v)}
      />

      <MenuList zIndex={3}>
        <MenuItem onClick={() => setValue("Download")}>Download</MenuItem>
        <MenuItem>Create a Copy</MenuItem>
        <MenuItem>Mark as Draft</MenuItem>
        <MenuItem>Delete</MenuItem>
        <MenuItem>Attend a Workshop</MenuItem>
      </MenuList>
    </Menu>
  );
};
