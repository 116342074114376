import React from "react";
import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
} from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import { BooksyLogo, Leaves } from "../icons";
import { HeroImageBackground } from "./styles";

export const HeroSection = () => {
  return (
    <HeroImageBackground pos="relative" mt={8}>
      <Container maxW="7xl">
        <Stack
          align="center"
          spacing={{ base: 4, md: 4 }}
          pb={{ base: 8, md: 8 }}
          px={{ base: 2, md: 4 }}
          direction={{ base: "column-reverse", md: "row" }}
        >
          <Stack
            flex={1}
            spacing={{ base: 4, md: 6 }}
            px={8}
            py={8}
            borderRadius="sm"
            bg="whiteAlpha.600"
          >
            <Heading
              lineHeight={1.1}
              fontWeight={600}
              fontSize={{ base: "3xl", sm: "4xl", lg: "5xl" }}
              textAlign={{ base: "center", md: "left" }}
              textColor="black"
              as="h1"
            >
              Trycholog Anna Wilkusz <br /> Sosnowiec
            </Heading>
            <Text
              textColor="black"
              fontSize={{ base: "xl" }}
              fontWeight="bold"
              textAlign={{ base: "center", md: "left" }}
            >
              Pomogę ci odzyskać zdrowe i piękne włosy oraz poprawić kondycję
              skóry!
            </Text>
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: "column", sm: "row" }}
              justify={{ base: "center", md: "start" }}
              align={{ base: "center", md: "start" }}
            >
              <Button
                size="lg"
                fontWeight="normal"
                px={6}
                variant="solid"
                as="a"
                href={process.env.GATSBY_BOOKSY_LINK || "#"}
                rel="noreferrer noopener"
                leftIcon={<BooksyLogo />}
              >
                Rezerwuj wizytę
              </Button>
              {/* <Button
                size="lg"
                variant="outline"
                as="a"
                href={`#bon-catch_4812499882e8a4aa771801958cc0a0a3`}
                colorScheme="white"
              >
                PREZENT 100 ZŁ
              </Button> */}
            </Stack>
          </Stack>
          <Flex
            flex={1}
            justify="center"
            align="center"
            position="relative"
            w="fit-content"
          >
            <Box position="relative" rounded="sm" width="fit-content" mt={-8}>
              <Box
                position="absolute"
                bottom={11}
                left={-10}
                w={12}
                h={12}
                zIndex={1}
                display={{ base: "none", md: "block" }}
              >
                <Leaves />
              </Box>
              <Box
                position="absolute"
                bottom={11}
                right={-10}
                w={12}
                h={12}
                zIndex={1}
                display={{ base: "none", md: "block" }}
                transform="scaleX(-1)"
              >
                <Leaves />
              </Box>
              <StaticImage
                alt="mgr Anna Wilkusz, założyciel Trichodermedica"
                quality={90}
                height={550}
                width={550}
                style={{
                  borderRadius: "0.125rem",
                  maxHeight: "550px",
                  maxWidth: "550px",
                }}
                layout="constrained"
                placeholder="blurred"
                loading="eager"
                src="../images/kontakt.png"
              />
            </Box>
          </Flex>
        </Stack>
      </Container>
    </HeroImageBackground>
  );
};
