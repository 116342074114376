import React from "react";
import { Flex, chakra, Container, Box } from "@chakra-ui/react";
import { NavigationMenu } from "./NavigationMenu";
import { Logo } from "./Logo";
import { useAnimateNavbarEffect } from "../hooks";
import { Link } from "gatsby";

export const NavigationBar = () => {
  const scrolled = useAnimateNavbarEffect();

  const bg = scrolled ? "#fff" : "transparent";
  const borderBottomColor = scrolled ? "brand.100" : "transparent";

  return (
    <chakra.header
      id="header"
      position="sticky"
      top={0}
      bg={bg}
      transition="0.2s ease-in-out"
      zIndex={2}
      borderBottom="1px solid"
      borderBottomColor={borderBottomColor}
    >
      <Container maxW="7xl">
        <Flex
          w="100%"
          px={{ base: 2, md: 8 }}
          py="6"
          align="center"
          justify="space-between"
          zIndex={2}
        >
          <Box
            w="auto"
            h={16}
            as={Link}
            to="/"
            aria-label="Logo Trichodermedica - link do strony głównej"
          >
            <Logo />
          </Box>
          <NavigationMenu />
        </Flex>
      </Container>
    </chakra.header>
  );
};
