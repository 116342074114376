import React from "react";
import {
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";

type TrichologyTabsProps = {
  diagnostics: React.ReactNode[];
  illnesses: React.ReactNode[];
  trichologics: React.ReactNode[];
  hairTreatments: React.ReactNode[];
};

const tabs = [
  "#diagnostyka",
  "#schorzenia-skory-glowy",
  "#zabiegi-trychologiczne",
  "#zabiegi-na-wlosy",
];

export const TrichologyTabs = ({
  diagnostics,
  illnesses,
  trichologics,
  hairTreatments,
}: TrichologyTabsProps) => {
  const { hash } = useLocation();

  const index = tabs.findIndex((tab) => tab === hash) || 0;

  const selectedIndex = index === -1 ? 0 : index;

  return (
    <Tabs
      variant="soft-rounded"
      colorScheme="brand"
      onChange={(index) => navigate(tabs[index])}
      index={selectedIndex}
    >
      <TabList
        flexWrap={{ base: "wrap", md: "nowrap" }}
        justifyContent={{ base: "center", md: "flex-start" }}
        gap={{ base: 2, md: 0 }}
        px={6}
      >
        <Tab id="#diagnostyka">Diagnostyka</Tab>
        <Tab id="#schorzenia-skory-glowy">Schorzenia skóry głowy</Tab>
        <Tab id="#zabiegi-trychologiczne">Zabiegi trychologiczne</Tab>
        <Tab id="#zabiegi-na-wlosy">Zabiegi na włosy</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <SimpleGrid columns={3} minChildWidth={80}>
            {diagnostics}
          </SimpleGrid>
        </TabPanel>
        <TabPanel>
          <SimpleGrid columns={3} minChildWidth={80}>
            {illnesses}
          </SimpleGrid>
        </TabPanel>
        <TabPanel>
          <SimpleGrid columns={3} minChildWidth={80}>
            {trichologics}
          </SimpleGrid>
        </TabPanel>
        <TabPanel>
          <SimpleGrid columns={3} minChildWidth={80}>
            {hairTreatments}
          </SimpleGrid>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
