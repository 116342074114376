import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const WaterIcon = (props: IconProps) => (
  <Icon width="800px" height="800px" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      color="currentColor"
      stroke="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0001 13.3848C16.0001 14.6088 15.526 15.7828 14.6821 16.6483C14.203 17.1397 13.6269 17.5091 13 17.7364M19 13.6923C19 7.11538 12 2 12 2C12 2 5 7.11538 5 13.6923C5 15.6304 5.7375 17.4893 7.05025 18.8598C8.36301 20.2302 10.1436 20.9994 12.0001 20.9994C13.8566 20.9994 15.637 20.2298 16.9497 18.8594C18.2625 17.4889 19 15.6304 19 13.6923Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
