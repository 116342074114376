import React from "react";
import {
  Button,
  Container,
  Flex,
  Heading,
  Text,
  VStack,
  chakra,
} from "@chakra-ui/react";
import { HeroBackground } from "./styles";
import { phoneNumberConvert } from "./utils";
import { BooksyLogo } from "../icons";

const phoneNumber = Number(process.env.GATSBY_PHONE_NUMBER);

export const Bon50baner = () => {
  return (
    <chakra.section
      py={{ base: 6, md: 8 }}
      px={{ base: 2, md: 8 }}
      bg="brand.300"
      my={4}
      overflow="hidden"
    >
      <Container maxW="7xl" position="relative">
        <HeroBackground
          pos="absolute"
          right={-100}
          bottom={-300}
          width={400}
          height={400}
          borderRadius="full"
          opacity={0.6}
        />
        <HeroBackground
          pos="absolute"
          left={-100}
          top={-300}
          width={400}
          height={400}
          borderRadius="full"
          opacity={0.6}
        />
        <VStack color="white">
          <Heading
            as="h2"
            mb={2}
            fontSize={{ base: "2xl" }}
            fontWeight="bold"
            textAlign="center"
          >
            Prezent dla Ciebie!
          </Heading>
          <Text
            as="p"
            fontWeight="bold"
            fontSize="xl"
            textAlign="center"
            mb={2}
          >
            Bon zniżkowy 100 zł na zabiegi.
          </Text>
          <Flex
            gap={4}
            alignItems="center"
            flexWrap="wrap"
            justifyContent="center"
          >
            <Button
              variant="outline"
              as="a"
              href={`#bon-catch_4812499882e8a4aa771801958cc0a0a3`}
              colorScheme="white"
            >
              POBIERZ TERAZ
            </Button>
          </Flex>
        </VStack>
      </Container>
    </chakra.section>
  );
};
