import {
  Alert,
  AlertDescription,
  Text,
  AlertIcon,
  AlertTitle,
  CloseButton,
  useDisclosure,
  Box,
  Button,
  VStack,
  HStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation } from "@reach/router";

export const CookieBanner = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const location = useLocation();

  useEffect(() => {
    const consent = localStorage.getItem("consent");

    if (!consent) {
      onOpen();
    }
  }, [location.pathname]);

  const disallowConsent = () => {
    localStorage.setItem("consent", "denied");

    if (typeof window !== "undefined" && typeof window.gtag === "function") {
      window.gtag("consent", "update", {
        ad_storage: "denied",
        analytics_storage: "denied",
      });
    }
    if (typeof window !== "undefined" && typeof window.fbq === "function") {
      fbq("consent", "revoke");
    }
    onClose();
  };

  const allowConsent = () => {
    localStorage.setItem("consent", "granted");
    if (typeof window !== "undefined" && typeof window.gtag === "function") {
      window.gtag("consent", "update", {
        ad_storage: "granted",
        analytics_storage: "granted",
      });
    }
    if (typeof window !== "undefined" && typeof window.fbq === "function") {
      fbq("consent", "revoke");
    }
    onClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Alert
      status="info"
      zIndex={10}
      position="fixed"
      left={4}
      right={4}
      bottom={4}
      mx="auto"
      width="calc(100% - 2rem)"
      colorScheme="brand"
      variant="left-accent"
      borderRadius="sm"
      alignItems="flex-start"
    >
      <AlertIcon mt={2} boxSize={10} display={{ base: "none", md: "block" }} />
      <VStack spacing={2} w="100%" justify="start">
        <AlertTitle alignSelf="start">
          <Text fontSize="lg">Ta strona wykorzystuje pliki cookies</Text>
        </AlertTitle>
        <AlertDescription w="100%">
          <Text lineHeight="5" fontWeight="bold">
            Serwis wykorzystuje pliki cookies m.in. aby zbierać dane dotyczące
            ruchu na stronie.
          </Text>
        </AlertDescription>
        <Box alignSelf={{ base: "center", md: "end" }}>
          <HStack spacing={4} mt={2}>
            <Button size="sm" onClick={allowConsent}>
              Akceptuj
            </Button>
            <Button size="sm" variant="outline" onClick={disallowConsent}>
              Odrzuć
            </Button>
          </HStack>
        </Box>
      </VStack>

      <CloseButton
        alignSelf="flex-start"
        position="relative"
        right={-1}
        top={-1}
        onClick={disallowConsent}
      />
    </Alert>
  );
};
