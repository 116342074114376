import React from "react";
import { Container, Heading, chakra, SimpleGrid } from "@chakra-ui/react";
import { ListItem } from "./ListItem";
import { StaticImage } from "gatsby-plugin-image";

export const List = () => {
  return (
    <chakra.section py={{ base: 10, md: 14 }} px={{ base: 2, md: 8 }}>
      <Container maxW="7xl">
        <Heading
          as="h2"
          mb={16}
          fontSize={{ base: "2xl" }}
          fontWeight="bold"
          textAlign="center"
        >
          W naszym gabinecie trycholog:
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={4}>
          <ListItem
            index={1}
            description="przeprowadzi wywiad dotyczący schorzenia skóry głowy, stanu zdrowia oraz dotychczasowej pielęgnacji"
          >
            <StaticImage
              src="../images/kontrola-trychologiczna.jpg"
              width={600}
              height={400}
              alt="Arkusz badań"
            />
          </ListItem>

          <ListItem
            index={2}
            description="wykona badanie skóry głowy za pomocą trychoskopu"
          >
            <StaticImage
              src="../images/trichoskopia.jpg"
              width={600}
              height={400}
              alt="Badanie trychoskopem"
            />
          </ListItem>
          <ListItem
            index={3}
            description="zdiagnozuje przyczynę danego problemu 
            skóry głowy"
          >
            <StaticImage
              src="../images/atopowe-zapalenie-skory.jpg"
              width={600}
              height={400}
              alt="Zabieg oczyszczający"
            />
          </ListItem>
          <ListItem
            index={4}
            description="zaleci oraz wykona specjalistyczne zabiegi trychologiczne wraz z doborem preparatów"
          >
            <StaticImage
              src="../images/zabieg-oczyszczajacy.jpg"
              width={600}
              height={400}
              alt="Zabieg oczyszczający"
            />
          </ListItem>
        </SimpleGrid>
        <ListItem
          index={5}
          description="w przypadku potrzeby zaleci wykonanie badań analitycznych
            oraz skieruje do lekarza specjalisty m.in. endokrynologa, ginekologa,
            dermatologa, immunologa"
          last
        />
      </Container>
    </chakra.section>
  );
};
