import { chakra } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const HeroBackground = styled(chakra.section)`
  background-color: #d9f2ef;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23003b33' fill-opacity='0.04' fill-rule='evenodd'/%3E%3C/svg%3E");
  z-index: 0;
`;

export const HeroImageBackground = styled(chakra.section)`
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1280' height='560' preserveAspectRatio='none' viewBox='0 0 1280 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1011%26quot%3b)' fill='none'%3e%3crect width='1280' height='560' x='0' y='0' fill='rgba(230%2c 246%2c 244%2c 1)'%3e%3c/rect%3e%3cpath d='M-236.45 47.49C-122.31 61.95-93.19 295.05 132.99 321.89 359.18 348.73 390.38 568.1 502.44 580.52' stroke='rgba(208%2c 197%2c 126%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M-85.92 353.27C19.63 346.34 90.5 143.73 283 146.07 475.5 148.41 516.95 532.23 651.92 573.66' stroke='rgba(208%2c 197%2c 126%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M-22.18 448.84C87.68 446.75 183.19 302.97 392.5 303.24 601.82 303.51 685.23 553.12 807.19 563.61' stroke='rgba(208%2c 197%2c 126%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M-68.09 305.46C67.24 312.45 212.08 541.63 414.72 551.86 617.37 562.09 588.82 668.55 656.13 671.79' stroke='rgba(208%2c 197%2c 126%2c 1)' stroke-width='2'%3e%3c/path%3e%3cpath d='M-131.58 476.04C9.72 472.03 127.59 262.84 392.27 263.24 656.96 263.64 757.42 611.1 916.13 628.54' stroke='rgba(208%2c 197%2c 126%2c 1)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1011'%3e%3crect width='1280' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: cover;
`;

export const WaveBackground = styled(chakra.div)`
  background-color: #d9f2ef;
  background-image: url("data:image/svg+xml,%3Csvg width='100' height='20' viewBox='0 0 100 20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.184 20c.357-.13.72-.264 1.088-.402l1.768-.661C33.64 15.347 39.647 14 50 14c10.271 0 15.362 1.222 24.629 4.928.955.383 1.869.74 2.75 1.072h6.225c-2.51-.73-5.139-1.691-8.233-2.928C65.888 13.278 60.562 12 50 12c-10.626 0-16.855 1.397-26.66 5.063l-1.767.662c-2.475.923-4.66 1.674-6.724 2.275h6.335zm0-20C13.258 2.892 8.077 4 0 4V2c5.744 0 9.951-.574 14.85-2h6.334zM77.38 0C85.239 2.966 90.502 4 100 4V2c-6.842 0-11.386-.542-16.396-2h-6.225zM0 14c8.44 0 13.718-1.21 22.272-4.402l1.768-.661C33.64 5.347 39.647 4 50 4c10.271 0 15.362 1.222 24.629 4.928C84.112 12.722 89.438 14 100 14v-2c-10.271 0-15.362-1.222-24.629-4.928C65.888 3.278 60.562 2 50 2 39.374 2 33.145 3.397 23.34 7.063l-1.767.662C13.223 10.84 8.163 12 0 12v2z' fill='%23003b33' fill-opacity='0.04' fill-rule='evenodd'/%3E%3C/svg%3E");
`;

export const FixedButtons = styled.div`
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 10;
`;
