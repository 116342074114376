import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const BeautyHairIcon = (props: IconProps) => (
  <Icon
    width="512.000000pt"
    height="512.000000pt"
    viewBox="0 0 512.000000 512.000000"
    {...props}
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M4350 5022 c-18 -15 -25 -34 -31 -86 -5 -36 -17 -86 -28 -110 -38
-86 -126 -161 -218 -185 -48 -13 -73 -42 -73 -84 0 -22 22 -51 100 -133 82
-86 107 -121 144 -197 47 -95 76 -208 76 -292 0 -35 6 -52 25 -70 55 -56 135
-15 135 69 0 87 28 199 75 293 37 76 64 112 145 197 78 82 100 111 100 133 0
42 -25 71 -73 84 -92 25 -181 100 -218 185 -11 24 -23 74 -28 110 -9 75 -31
104 -81 104 -15 0 -38 -8 -50 -18z m89 -382 c17 -21 53 -54 79 -71 26 -18 48
-33 50 -35 2 -1 -23 -35 -55 -76 -32 -40 -69 -95 -83 -121 -14 -26 -27 -47
-30 -47 -3 0 -16 21 -30 47 -14 26 -52 81 -84 122 -35 44 -54 77 -47 79 23 8
109 81 131 111 12 17 25 31 29 31 4 0 22 -18 40 -40z"
      />
      <path
        d="M2480 4800 l0 -159 -37 -6 c-292 -43 -479 -116 -686 -265 -100 -72
-248 -228 -320 -337 -88 -131 -141 -248 -222 -483 -118 -345 -157 -540 -174
-870 -10 -203 -20 -226 -145 -346 -142 -138 -184 -240 -173 -424 11 -162 67
-320 161 -447 l45 -61 -55 -83 c-29 -45 -67 -118 -84 -161 -62 -161 -63 -167
-67 -615 l-4 -413 25 -25 25 -25 1791 0 1791 0 25 25 26 26 -5 412 c-4 444 -6
459 -63 606 -64 161 -189 324 -332 430 l-67 49 -37 204 c-97 524 -137 914
-138 1325 0 462 -66 723 -249 983 -97 139 -289 303 -446 382 -78 40 -222 86
-305 98 -136 21 -120 -3 -120 180 l0 160 -80 0 -80 0 0 -160z m0 -2199 l0
-1878 -76 -6 c-74 -7 -176 -37 -300 -90 -255 -110 -505 -81 -649 75 -61 66
-95 151 -95 239 0 68 -26 190 -55 255 -37 83 -79 138 -195 253 -95 95 -113
117 -153 201 -55 115 -81 231 -75 335 6 97 39 155 140 246 137 123 167 196
179 429 18 348 56 533 188 902 100 280 188 426 350 581 199 190 433 297 734
336 4 1 7 -844 7 -1878z m370 1833 c385 -119 675 -475 729 -899 7 -49 16 -236
21 -415 9 -338 12 -376 36 -625 22 -222 47 -378 126 -804 l71 -385 -44 -38
c-121 -107 -306 -207 -467 -254 -141 -41 -258 -54 -479 -54 l-203 0 0 1758 0
1759 63 -9 c34 -4 100 -20 147 -34z m1261 -3202 c26 -40 57 -98 69 -130 53
-140 54 -147 58 -514 l3 -348 -800 0 -801 0 0 279 0 279 253 5 c202 4 271 9
345 25 190 42 351 107 497 202 95 62 240 182 256 212 10 18 7 76 -7 133 -8 31
71 -58 127 -143z m-2961 -88 c28 -58 38 -105 58 -276 27 -221 207 -402 452
-454 151 -32 375 1 548 82 84 38 176 64 235 64 l37 0 0 -160 0 -160 -801 0
-800 0 4 348 c3 296 6 357 22 412 22 81 63 172 106 239 l34 52 38 -43 c21 -24
51 -70 67 -104z"
      />
      <path
        d="M2211 4229 c-71 -14 -186 -74 -242 -126 -52 -48 -109 -118 -109 -134
0 -5 28 -27 62 -50 l62 -42 25 34 c68 91 148 142 254 163 l57 11 0 77 0 78
-32 -1 c-18 -1 -53 -5 -77 -10z"
      />
      <path
        d="M1797 3453 c-56 -121 -92 -235 -106 -337 -30 -212 6 -349 127 -487
88 -101 96 -127 100 -311 l4 -158 79 0 79 0 0 143 c0 210 -31 305 -138 428
-85 98 -97 132 -96 269 2 142 24 232 94 378 28 58 49 106 48 107 -9 8 -129 65
-136 65 -5 0 -29 -44 -55 -97z"
      />
      <path
        d="M1404 2210 c-90 -140 -132 -282 -121 -413 9 -101 42 -162 132 -244
94 -85 105 -111 105 -248 l0 -105 80 0 80 0 0 108 c0 178 -35 260 -148 352
-76 62 -97 110 -89 206 8 89 34 161 92 250 25 38 45 72 45 76 0 7 -114 88
-125 88 -3 0 -26 -32 -51 -70z"
      />
      <path
        d="M1757 1042 c-26 -26 -47 -53 -47 -59 0 -17 100 -90 171 -123 83 -38
182 -60 282 -60 l77 0 0 80 0 80 -73 0 c-117 0 -225 35 -308 101 -20 16 -41
29 -46 29 -5 0 -30 -22 -56 -48z"
      />
      <path
        d="M2955 4080 c-37 -37 -47 -54 -40 -64 6 -7 38 -47 72 -87 151 -179
253 -418 283 -659 6 -51 10 -353 10 -757 l0 -673 81 0 80 0 -4 733 c-3 702 -4
736 -24 825 -45 195 -109 352 -203 497 -64 97 -177 235 -194 235 -6 0 -34 -23
-61 -50z"
      />
      <path d="M560 3760 l0 -160 80 0 80 0 0 160 0 160 -80 0 -80 0 0 -160z" />
      <path d="M320 3360 l0 -80 120 0 120 0 0 80 0 80 -120 0 -120 0 0 -80z" />
      <path d="M720 3360 l0 -80 120 0 120 0 0 80 0 80 -120 0 -120 0 0 -80z" />
      <path d="M560 2960 l0 -160 80 0 80 0 0 160 0 160 -80 0 -80 0 0 -160z" />
    </g>
  </Icon>
);
