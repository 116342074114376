import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const ShampooIcon = (props: IconProps) => (
  <Icon viewBox="0 0 288 511.18" {...props}>
    <path
      color="currentColor"
      stroke="currentColor"
      fill="currentColor"
      fillRule="nonzero"
      d="M17.21 191.43v279.12c0 6.4 2.65 12.27 6.9 16.53 4.26 4.23 10.11 6.89 16.52 6.89h199.8c6.42 0 12.27-2.65 16.52-6.9s6.9-10.11 6.9-16.52l.02-279.81c-.65-18.06-22.43-31.42-52.48-39.47-18.21-4.87-39.24-7.66-60.63-8.3-21.51-.65-43.39.84-63.21 4.51-37.91 7.04-67.79 21.7-70.34 43.95zm50.94 62.17v143.34c0 9.6 7.91 17.57 17.57 17.57h109.62c9.66 0 17.57-7.93 17.57-17.57V253.6c-.41-40.87-140.89-42.57-144.76 0zM57.1 33.93c-9.37 0-16.97-7.6-16.97-16.96C40.13 7.6 47.73 0 57.1 0h183.38c4.8 0 9.15 2 12.23 5.21l30.32 30.33c6.63 6.62 6.63 17.36 0 23.98-6.62 6.63-17.36 6.63-23.98 0l-25.59-25.59H172.4v46.59h21.73c4.21 0 7.62 3.4 7.62 7.62v43.26a225 225 0 0 1 14.07 3.27c37.29 9.99 64.31 28.51 65.18 55.61l.06 280.27c0 11.16-4.58 21.33-11.94 28.69-7.35 7.35-17.52 11.94-28.69 11.94H40.63c-11.16 0-21.33-4.59-28.68-11.94C4.58 491.83 0 481.66 0 470.55V191.28l.07-.77c2.78-30.54 36.18-49.93 79.24-58.88V88.14c0-4.17 3.4-7.58 7.62-7.58h23.26V33.93H57.1zm100.15 0h-31.76v46.43h31.76V33.93zm29.3 94.87V95.76H94.56v33.18c18.17-2.66 37.57-3.69 56.67-3.11 12.01.36 23.91 1.35 35.32 2.97z"
    />
  </Icon>
);
