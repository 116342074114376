import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const ImageWrapper = styled.div`
  max-height: 220px;
  width: auto;
  position: relative;
  overflow: hidden;
  display: flex !important;
  justify-content: center;
  width: 100% !important;
  height: 100% !important;
  cursor: pointer;
  border-radius: 0.125rem;

  .gatsby-image {
    transition: 0.3s ease-in-out;
    transform: scale(1);

    &:hover {
      transition: 0.3s ease-in-out;
      transform: scale(1.05);
    }
  }
`;

export const ImagePostWrapper = styled(Box)`
  height: 500px;
  position: relative;
  overflow: hidden;
  display: flex !important;
  justify-content: center;
  width: 100% !important;

  @media (max-width: 30em) {
    height: 200px;
  }
`;

export const ImageBlur = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100% !important;
  height: 100% !important;
  background-size: cover;
  filter: blur(16px);
`;

export const ImageRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
`;

export const ImageCol = styled.div`
  flex: 33.3%;
  max-width: 33.3%;
  padding: 0 4px;

  .gatsby-image-wrapper {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;

    & > div {
      border-radius: 0.125rem;
    }
  }

  @media screen and (max-width: 48em) {
    flex: 50%;
    max-width: 50%;
  }

  @media screen and (max-width: 30em) {
    flex: 100%;
    max-width: 100%;
  }
`;
