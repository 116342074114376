import { Box, Card, CardBody, Stack, Text, Heading } from "@chakra-ui/react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { ImageWrapper, ImageBlur } from "./styles";
import { TArticleData } from "../types";

export const ArticleCard = (edge: TArticleData) => {
  const image = getImage(
    edge.node.frontmatter.featuredimage?.childImageSharp?.gatsbyImageData
  );
  const fallback =
    edge.node.frontmatter.featuredimage?.childImageSharp?.gatsbyImageData
      ?.images?.fallback?.src;

  return (
    <Box key={edge.node.id} as={Link} to={edge.node.fields.slug}>
      <Card>
        <CardBody>
          {image && (
            <ImageWrapper>
              <GatsbyImage
                className="gatsby-image"
                image={image}
                objectFit="contain"
                alt={edge.node.frontmatter.title}
                style={{
                  borderRadius: "0.125rem",
                  height: "220px",
                  zIndex: 1,
                }}
              />
              <ImageBlur style={{ backgroundImage: `url(${fallback})` }} />
            </ImageWrapper>
          )}
          <Stack mt="6" spacing="3">
            <Heading size="md" color="brand.900">
              {edge.node.frontmatter.title}
            </Heading>
            <Text noOfLines={3} color="brand.900">
              {edge.node.frontmatter.description}
            </Text>
          </Stack>
        </CardBody>
      </Card>
    </Box>
  );
};
