import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const DiagnosisIcon = (props: IconProps) => (
  <Icon viewBox="0 0 297 297" fill="currentColor" {...props}>
    <g>
      <path
        d="M176.816,188.027h-18.248v-18.248c0-5.56-4.508-10.068-10.068-10.068s-10.068,4.508-10.068,10.068v18.248h-18.248
		c-5.56,0-10.068,4.508-10.068,10.068s4.508,10.068,10.068,10.068h18.248v18.248c0,5.56,4.508,10.068,10.068,10.068
		s10.068-4.508,10.068-10.068v-18.248h18.248c5.56,0,10.068-4.508,10.068-10.068S182.376,188.027,176.816,188.027z"
      />
      <path
        d="M286.932,112.476h-7.236V18.72c0-5.56-4.508-10.068-10.068-10.068H27.372c-5.56,0-10.068,4.508-10.068,10.068v59.148
		h-7.236C4.508,77.868,0,82.376,0,87.936v156.872c0,24.008,19.532,43.54,43.54,43.54H253.46c24.008,0,43.54-19.532,43.54-43.54
		V122.544C297,116.984,292.492,112.476,286.932,112.476z M37.44,28.788H259.56v83.689H120.115l-14.522-29.042
		c-1.706-3.412-5.191-5.566-9.005-5.566H37.44V28.788z M276.864,244.808c0,12.905-10.499,23.405-23.405,23.405H43.54
		c-12.905,0-23.405-10.499-23.405-23.405V98.004h70.23l14.522,29.042c1.706,3.412,5.191,5.566,9.005,5.566h162.972V244.808z"
      />
      <path
        d="M148.5,60.375h86.52c3.892,0,7.047-3.155,7.047-7.047s-3.155-7.047-7.047-7.047H148.5c-3.892,0-7.047,3.155-7.047,7.047
		S144.608,60.375,148.5,60.375z"
      />
      <path
        d="M120.939,53.328c0-3.892-3.155-7.047-7.047-7.047H79.284c-3.892,0-7.047,3.155-7.047,7.047s3.155,7.047,7.047,7.047h34.608
		C117.784,60.375,120.939,57.22,120.939,53.328z"
      />
      <path
        d="M148.5,94.983h86.52c3.892,0,7.047-3.155,7.047-7.047c0-3.892-3.155-7.047-7.047-7.047H148.5
		c-3.892,0-7.047,3.155-7.047,7.047C141.453,91.828,144.608,94.983,148.5,94.983z"
      />
    </g>
  </Icon>
);
