exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bon-podarunkowy-tsx": () => import("./../../../src/pages/bon-podarunkowy.tsx" /* webpackChunkName: "component---src-pages-bon-podarunkowy-tsx" */),
  "component---src-pages-cennik-tsx": () => import("./../../../src/pages/cennik.tsx" /* webpackChunkName: "component---src-pages-cennik-tsx" */),
  "component---src-pages-efekty-zabiegow-tsx": () => import("./../../../src/pages/efekty-zabiegow.tsx" /* webpackChunkName: "component---src-pages-efekty-zabiegow-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-kosmetologia-index-tsx": () => import("./../../../src/pages/kosmetologia/index.tsx" /* webpackChunkName: "component---src-pages-kosmetologia-index-tsx" */),
  "component---src-pages-masaze-index-tsx": () => import("./../../../src/pages/masaze/index.tsx" /* webpackChunkName: "component---src-pages-masaze-index-tsx" */),
  "component---src-pages-o-mnie-tsx": () => import("./../../../src/pages/o-mnie.tsx" /* webpackChunkName: "component---src-pages-o-mnie-tsx" */),
  "component---src-pages-po-2-tsx": () => import("./../../../src/pages/po2.tsx" /* webpackChunkName: "component---src-pages-po-2-tsx" */),
  "component---src-pages-po-tsx": () => import("./../../../src/pages/po.tsx" /* webpackChunkName: "component---src-pages-po-tsx" */),
  "component---src-pages-potwierdzenie-tsx": () => import("./../../../src/pages/potwierdzenie.tsx" /* webpackChunkName: "component---src-pages-potwierdzenie-tsx" */),
  "component---src-pages-strefa-meska-index-tsx": () => import("./../../../src/pages/strefa-meska/index.tsx" /* webpackChunkName: "component---src-pages-strefa-meska-index-tsx" */),
  "component---src-pages-stylizacja-paznokci-index-tsx": () => import("./../../../src/pages/stylizacja-paznokci/index.tsx" /* webpackChunkName: "component---src-pages-stylizacja-paznokci-index-tsx" */),
  "component---src-pages-stylizacja-rzes-i-brwi-index-tsx": () => import("./../../../src/pages/stylizacja-rzes-i-brwi/index.tsx" /* webpackChunkName: "component---src-pages-stylizacja-rzes-i-brwi-index-tsx" */),
  "component---src-pages-trychologia-index-tsx": () => import("./../../../src/pages/trychologia/index.tsx" /* webpackChunkName: "component---src-pages-trychologia-index-tsx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-fryzjerstwo-przedluzanie-i-zageszczanie-wlosow-metoda-tape-classic-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/fryzjerstwo/przedluzanie-i-zageszczanie-wlosow-metoda-tape-classic.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-fryzjerstwo-przedluzanie-i-zageszczanie-wlosow-metoda-tape-classic-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-eksfoliacja-kwasami-a-peel-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/kosmetologia/eksfoliacja-kwasami-a-peel.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-eksfoliacja-kwasami-a-peel-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-eksfoliacja-kwasami-f-peel-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/kosmetologia/eksfoliacja-kwasami-f-peel.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-eksfoliacja-kwasami-f-peel-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-eksfoliacja-kwasami-g-peel-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/kosmetologia/eksfoliacja-kwasami-g-peel.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-eksfoliacja-kwasami-g-peel-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-eksfoliacja-kwasami-r-peel-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/kosmetologia/eksfoliacja-kwasami-r-peel.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-eksfoliacja-kwasami-r-peel-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-eksfoliacja-kwasami-s-peel-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/kosmetologia/eksfoliacja-kwasami-s-peel.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-eksfoliacja-kwasami-s-peel-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-eksfoliacja-kwasami-x-peel-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/kosmetologia/eksfoliacja-kwasami-x-peel.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-eksfoliacja-kwasami-x-peel-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-hydrabrazja-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/kosmetologia/hydrabrazja.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-hydrabrazja-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-mezoterapia-mikroiglowa-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/kosmetologia/mezoterapia-mikroigłowa.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-mezoterapia-mikroiglowa-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-mikrodermoabrazja-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/kosmetologia/mikrodermoabrazja.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-mikrodermoabrazja-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-oxybrazja-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/kosmetologia/oxybrazja.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-oxybrazja-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-przekluwanie-uszu-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/kosmetologia/przekluwanie-uszu.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-przekluwanie-uszu-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-pure-acne-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/kosmetologia/pure-acne.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-pure-acne-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-pure-antiaging-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/kosmetologia/pure-antiaging.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-pure-antiaging-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-pure-eye-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/kosmetologia/pure-eye.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-pure-eye-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-pure-global-lift-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/kosmetologia/pure-global-lift.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-pure-global-lift-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-pure-hydra-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/kosmetologia/pure-hydra.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-pure-hydra-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-pure-m-peel-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/kosmetologia/pure-m-peel.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-pure-m-peel-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-pure-man-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/kosmetologia/pure-man.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-pure-man-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-pure-oxygen-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/kosmetologia/pure-oxygen.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-pure-oxygen-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-pure-sensi-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/kosmetologia/pure-sensi.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-kosmetologia-pure-sensi-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-masaze-drenaz-limfatyczny-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/masaze/drenaz-limfatyczny.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-masaze-drenaz-limfatyczny-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-masaze-masaz-kobido-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/masaze/masaz-kobido.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-masaze-masaz-kobido-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-masaze-masaz-leczniczy-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/masaze/masaz-leczniczy.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-masaze-masaz-leczniczy-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-masaze-masaz-liftingujacy-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/masaze/masaz-liftingujacy.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-masaze-masaz-liftingujacy-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-masaze-masaz-relaksacyjny-ciala-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/masaze/masaz-relaksacyjny-ciala.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-masaze-masaz-relaksacyjny-ciala-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-masaze-masaz-sportowy-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/masaze/masaz-sportowy.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-masaze-masaz-sportowy-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-strefa-meska-balance-skin-men-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/strefa-meska/balance-skin-men.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-strefa-meska-balance-skin-men-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-strefa-meska-hydra-skin-men-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/strefa-meska/hydra-skin-men.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-strefa-meska-hydra-skin-men-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-strefa-meska-manicure-meski-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/strefa-meska/manicure-meski.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-strefa-meska-manicure-meski-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-strefa-meska-pedicure-men-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/strefa-meska/pedicure-men.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-strefa-meska-pedicure-men-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-strefa-meska-pure-skin-men-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/strefa-meska/pure-skin.men.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-strefa-meska-pure-skin-men-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-paznokci-manicure-hybrydowy-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/stylizacja-paznokci/manicure-hybrydowy.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-paznokci-manicure-hybrydowy-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-paznokci-manicure-klasyczny-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/stylizacja-paznokci/manicure-klasyczny.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-paznokci-manicure-klasyczny-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-paznokci-manicure-spa-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/stylizacja-paznokci/manicure-spa.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-paznokci-manicure-spa-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-paznokci-pedicure-hybrydowy-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/stylizacja-paznokci/pedicure-hybrydowy.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-paznokci-pedicure-hybrydowy-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-paznokci-pedicure-klasyczny-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/stylizacja-paznokci/pedicure-klasyczny.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-paznokci-pedicure-klasyczny-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-paznokci-pedicure-spa-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/stylizacja-paznokci/pedicure-spa.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-paznokci-pedicure-spa-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-paznokci-przedluzanie-paznokci-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/stylizacja-paznokci/przedluzanie-paznokci.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-paznokci-przedluzanie-paznokci-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-paznokci-zabieg-parafinowy-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/stylizacja-paznokci/zabieg-parafinowy.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-paznokci-zabieg-parafinowy-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-rzes-i-brwi-henna-pudrowa-brwi-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/stylizacja-rzes-i-brwi/henna-pudrowa-brwi.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-rzes-i-brwi-henna-pudrowa-brwi-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-rzes-i-brwi-laminacja-brwi-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/stylizacja-rzes-i-brwi/laminacja-brwi.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-rzes-i-brwi-laminacja-brwi-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-rzes-i-brwi-lifting-rzes-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/stylizacja-rzes-i-brwi/lifting-rzes.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-rzes-i-brwi-lifting-rzes-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-rzes-i-brwi-stylizacja-rzes-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/stylizacja-rzes-i-brwi/stylizacja-rzes.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-stylizacja-rzes-i-brwi-stylizacja-rzes-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-analiza-pierwiastkowa-wlosow-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/analiza-pierwiastkowa-wlosow.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-analiza-pierwiastkowa-wlosow-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-atopowe-zapalenie-skory-glowy-azs-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/atopowe-zapalenie-skory-glowy-azs.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-atopowe-zapalenie-skory-glowy-azs-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-bioplastia-organiczna-wlosow-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/bioplastia-organiczna-wlosow.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-bioplastia-organiczna-wlosow-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-darsonwalizacja-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/darsonwalizacja.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-darsonwalizacja-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-dr-cyj-fair-filler-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/dr-cyj-fair-filler.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-dr-cyj-fair-filler-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-fototerapia-lllt-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/fototerapia-lllt.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-fototerapia-lllt-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-hair-massager-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/hair-massager.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-hair-massager-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-head-spa-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/head-spa.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-head-spa-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-infuzja-tlenowa-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/infuzja-tlenowa.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-infuzja-tlenowa-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-keratynowe-prostowanie-wlosow-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/keratynowe-prostowanie-wlosow.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-keratynowe-prostowanie-wlosow-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-koloryzacja-trychologiczna-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/koloryzacja-trychologiczna.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-koloryzacja-trychologiczna-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-konsultacja-trychologiczna-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/konsultacja-trychologiczna.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-konsultacja-trychologiczna-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lampa-dermalight-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/lampa-dermalight.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lampa-dermalight-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lojotok-skory-glowy-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/lojotok-skory-glowy.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lojotok-skory-glowy-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lojotokowe-zapalenie-skory-glowy-lzs-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/lojotokowe-zapalenie-skory-glowy-lzs.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lojotokowe-zapalenie-skory-glowy-lzs-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lupiez-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/lupiez.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lupiez-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-luszczyca-skory-glowy-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/luszczyca-skory-glowy.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-luszczyca-skory-glowy-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lysienie-anagenowe-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/lysienie-anagenowe.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lysienie-anagenowe-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lysienie-androgenowe-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/lysienie-androgenowe.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lysienie-androgenowe-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lysienie-bliznowaciejace-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/lysienie-bliznowaciejace.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lysienie-bliznowaciejace-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lysienie-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/lysienie.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lysienie-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lysienie-menopauzalne-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/lysienie-menopauzalne.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lysienie-menopauzalne-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lysienie-plackowate-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/lysienie-plackowate.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lysienie-plackowate-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lysienie-polekowe-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/lysienie-polekowe.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lysienie-polekowe-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lysienie-psychogenne-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/lysienie-psychogenne.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lysienie-psychogenne-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lysienie-telogenowe-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/lysienie-telogenowe.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lysienie-telogenowe-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lysienie-trakcyjne-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/lysienie-trakcyjne.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-lysienie-trakcyjne-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-mezoterapia-iglowa-skory-glowy-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/mezoterapia-iglowa-skory-glowy.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-mezoterapia-iglowa-skory-glowy-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-mezoterapia-mikroiglowa-skory-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/mezoterapia-mikroiglowa-skory.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-mezoterapia-mikroiglowa-skory-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-nadmierne-wypadanie-wlosow-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/nadmierne-wypadanie-wlosow.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-nadmierne-wypadanie-wlosow-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-nanoplastia-wlosow-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/nanoplastia-wlosow.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-nanoplastia-wlosow-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-siwienie-wlosow-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/siwienie-wlosow.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-siwienie-wlosow-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-sucha-i-wrazliwa-skora-glowy-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/sucha-i-wrazliwa-skora-glowy.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-sucha-i-wrazliwa-skora-glowy-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-suche-i-zniszczone-wlosy-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/suche-i-zniszczone-wlosy.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-suche-i-zniszczone-wlosy-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-trichodynia-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/trichodynia.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-trichodynia-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-zabieg-kojaco-nawilzajacy-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/zabieg-kojaco-nawilzajacy.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-zabieg-kojaco-nawilzajacy-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-zabieg-lagodzacy-zmiany-luszczycowe-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/zabieg-lagodzacy-zmiany-luszczycowe.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-zabieg-lagodzacy-zmiany-luszczycowe-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-zabieg-oczyszczajacy-skore-glowy-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/zabieg-oczyszczajacy-skore-glowy.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-zabieg-oczyszczajacy-skore-glowy-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-zabieg-przeciwlojotokowy-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/zabieg-przeciwlojotokowy.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-zabieg-przeciwlojotokowy-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-zabieg-przeciwlupiezowy-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/zabieg-przeciwlupiezowy.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-zabieg-przeciwlupiezowy-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-zabieg-regeneracyjno-odzywczy-na-wlosy-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/zabieg-regeneracyjno-odzywczy-na-wlosy.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-zabieg-regeneracyjno-odzywczy-na-wlosy-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-zabieg-wzmacniajacy-stymulujacy-odrost-wlosow-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/zabieg-wzmacniajacy-stymulujacy-odrost-wlosow.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-zabieg-wzmacniajacy-stymulujacy-odrost-wlosow-mdx" */),
  "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-zapalenie-mieszkow-wlosowych-mdx": () => import("./../../../src/templates/post-template.tsx?__contentFilePath=/opt/build/repo/src/content/trychologia/zapalenie-mieszkow-wlosowych.mdx" /* webpackChunkName: "component---src-templates-post-template-tsx-content-file-path-src-content-trychologia-zapalenie-mieszkow-wlosowych-mdx" */)
}

