import {
  extendTheme,
  theme as defaultTheme,
  withDefaultColorScheme,
} from "@chakra-ui/react";

export default extendTheme(
  {
    ...defaultTheme,
    colors: {
      ...defaultTheme.colors,
      brand: {
        50: "#e6f6f4",
        100: "#d9f2ef",
        200: "#b0e4dd",
        300: "#00a991",
        400: "#009883",
        500: "#008774",
        600: "#007f6d",
        700: "#006557",
        800: "#004c41",
        900: "#003b33",
      },
      primary: "brand",
    },
    fonts: {
      heading: `Playfair Display Variable, sans-serif`,
      body: `Cardo, sans-serif`,
    },
    components: {
      Button: {
        baseStyle: {
          fontFamily: "system-ui, monospace",
          textTransform: "uppercase",
        },
      },
      Card: {
        baseStyle: {
          container: {
            shadow: "none",
          },
          header: {
            fontFamily: `Cardo, sans-serif`,
            fontSize: "36px",
            fontWeight: "bold",
            paddingLeft: 2,
            paddingBottom: 2,
          },
          body: {
            fontFamily: `Cardo, sans-serif`,
            fontSize: "16px",
            fontWeight: "bold",
            paddingLeft: 2,
          },
        },
      },
    },
  },
  withDefaultColorScheme({ colorScheme: "brand" })
);
