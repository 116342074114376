import { useState, useEffect } from "react";

export const useAnimateNavbarEffect = () => {
  const [scrolled, setScrolled] = useState(false);

  const navHeight = 112;

  useEffect(() => {
    const listener = () => {
      if (window.scrollY > navHeight / 2) {
        setScrolled(true);
      } else setScrolled(false);
    };
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, []);

  return scrolled;
};
