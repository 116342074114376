import React from "react";
import { useSiteMetadata } from "../hooks";
import { Script } from "gatsby";

type SeoProps = {
  description?: string;
  title?: string;
  image?: string;
  pathname?: string;
  keywords?: string;
  children?: React.ReactNode;
};

export const SeoPo = ({
  description,
  pathname,
  image,
  keywords,
  children,
}: SeoProps) => {
  const {
    description: defaultDescription,
    image: siteImage,
    siteUrl,
    icon,
  } = useSiteMetadata();

  const seo = {
    title: "Potwierdzenie",
    description: description || defaultDescription,
    image: `${siteUrl}${image || siteImage}`,
    url: `${siteUrl}${pathname || ``}`,
    keywords: keywords || "",
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="Polish" />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <link rel="icon" href={icon} />
      <meta name="author" content="Trichodermedica" />
      <meta name="copyright" content="Trichodermedica" />
      <meta
        name="keywords"
        content={`Trichodermedica, Trichodermedica sosnowiec, trycholog Sosnowiec, trycholog śląsk, trycholog Środula, trycholog Bolesława Prusa 54, sosnowiec, trychologia w Sosnowcu, Gabinety Kosmetyczne, kosmetologia w sosnowcu, kosmetolog Sosnowiec, ${seo.keywords}`}
      />
      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      {/* OG */}
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:site_name" content={seo.title} />
      <meta property="og:locale" content="pl_PL" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={seo.url} />
      <meta property="og:image" content={seo.image} />
      {/* Icons */}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#008774" />
      <meta name="msapplication-TileColor" content="#e6f6f4" />
      <meta name="theme-color" content="#e6f6f4" />
      <link rel="canonical" href={seo.url} />
      {/* Made error to avoid deploy - need information */}
      {/* <!-- Google tag (gtag.js) --> <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11448848939"></script> <script> window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-11448848939'); </script> */}
      {/* Google tag (gtag.js) */}
      <Script
        // You should have set value AW-11448848939 as .env value
        // Also, GTAG is already configured
        // I leave your code improved
        src={`https://www.googletagmanager.com/gtag/js?id=AW-11448848939`}
        strategy="idle"
        forward={[`gtag`]}
      />
      <Script
        id="gtag-config"
        strategy="idle"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || []; 
          function gtag() { 
            dataLayer.push(arguments); 
          };
          gtag('js', new Date()); 
          gtag('config', 'AW-11448848939');
          `,
        }}
      />

      <Script
        src="https://app.boneter.com/assets/dist/widget/js/widget-catcher.js?bon_widget_catcher=4812499882e8a4aa771801958cc0a0a3"
        strategy="idle"
        type="text/javascript"
      />
      {children}
    </>
  );
};
