import React from "react";

export const Leaves = () => {
  return (
    <svg
      id="Warstwa_2"
      data-name="Warstwa 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.06 50.33"
    >
      <g id="Warstwa_1-2" data-name="Warstwa 1">
        <g>
          <path
            fill="#d0c57e"
            fillRule="evenodd"
            strokeWidth="0px"
            d="m14.65,30.19c-2.83-1.4-4.65-6.72-5.48-9.16-1.99-5.88-2.18-12.56-.38-18.67.33-1.12.22-2.88,1.43-2.21.71.39,1.48,1.86,1.92,2.5,3.97,5.8,6.54,13.46,5.42,20.61-.32,2.05-.88,5.46-2.28,7.17-.21-.06-.42-.14-.63-.24Z"
          />
          <path
            fill="#d0c57e"
            fillRule="evenodd"
            strokeWidth="0px"
            d="m26.81,42.11c-2.55-1.85-3.45-7.41-3.85-9.96-.97-6.13-.02-12.75,2.78-18.47.51-1.04.71-2.8,1.78-1.93.63.51,1.14,2.08,1.47,2.79,2.93,6.39,4.17,14.37,1.86,21.23-.66,1.97-1.79,5.23-3.46,6.68-.2-.09-.39-.21-.58-.34Z"
          />
          <path
            fill="#d0c57e"
            fillRule="evenodd"
            strokeWidth="0px"
            d="m25.53,50.04c-2.95,1.12-8.08-1.19-10.43-2.25-5.65-2.56-10.67-6.99-13.9-12.47-.59-1-1.95-2.13-.64-2.55.77-.25,2.37.19,3.14.31,6.95,1.06,14.3,4.41,18.76,10.11,1.28,1.64,3.38,4.37,3.68,6.56-.19.12-.39.22-.6.3Z"
          />
        </g>
      </g>
    </svg>
  );
};
