import { Icon, IconProps } from "@chakra-ui/react";
import * as React from "react";

export const HealIcon = (props: IconProps) => (
  <Icon width="800px" height="800px" viewBox="0 0 32 32" {...props}>
    <path
      color="currentColor"
      stroke="currentColor"
      fill="currentColor"
      d="M16,12c0,0.552-0.448,1-1,1c-0.552,0-1-0.448-1-1s0.448-1,1-1C15.552,11,16,11.448,16,12z M12,14
      c-0.552,0-1,0.448-1,1s0.448,1,1,1c0.552,0,1-0.448,1-1S12.552,14,12,14z M20,16c-0.552,0-1,0.448-1,1s0.448,1,1,1
      c0.552,0,1-0.448,1-1S20.552,16,20,16z M17,19c-0.552,0-1,0.448-1,1s0.448,1,1,1c0.552,0,1-0.448,1-1S17.552,19,17,19z M19,12
      c-0.552,0-1,0.448-1,1s0.448,1,1,1c0.552,0,1-0.448,1-1S19.552,12,19,12z M13,18c-0.552,0-1,0.448-1,1s0.448,1,1,1
      c0.552,0,1-0.448,1-1S13.552,18,13,18z M16,15c-0.552,0-1,0.448-1,1c0,0.552,0.448,1,1,1s1-0.448,1-1C17,15.448,16.552,15,16,15z
       M16,4.686c-1.562-1.562-3.609-2.343-5.657-2.343c-2.047,0-4.095,0.781-5.657,2.343c-3.124,3.124-3.124,8.19,0,11.314L16,27.314
      c1.562,1.562,3.609,2.343,5.657,2.343s4.094-0.78,5.657-2.343c3.141-3.141,3.124-8.19,0-11.314L16,4.686z M26.607,26.607
      c-1.322,1.322-3.08,2.05-4.95,2.05s-3.628-0.728-4.95-2.05L5.393,15.293c-2.729-2.729-2.729-7.17,0-9.9
      c1.322-1.322,3.08-2.05,4.95-2.05c1.87,0,3.628,0.728,4.95,2.05l11.314,11.314C29.336,19.436,29.336,23.877,26.607,26.607z"
    />
  </Icon>
);
