import styled from "@emotion/styled";

export const CarouselContainer = styled.div`
  .slick-slide {
    margin: 0 8px;
    height: auto !important;
  }
  .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
  }
`;
