import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const CactusIcon = (props: IconProps) => (
  <Icon viewBox="0 0 512 512" {...props}>
    <g>
      <path
        fill="currentColor"
        d="M402.717,118.054c-23.12,0-43.393,12.41-54.518,30.922V88.482C348.199,39.694,308.509,0,259.72,0
		c-48.792,0-88.482,39.694-88.482,88.482v135.176c-6.455-28.243-31.782-49.387-61.955-49.387c-35.039,0-63.54,28.504-63.54,63.548
		v33.224c-0.008,16.194,3.181,31.935,9.463,46.778c9.08,21.443,24.095,39.693,43.428,52.771
		c19.843,13.439,43.063,20.553,67.138,20.553h5.465v99.711V512h21.144h134.677h21.14v-21.144V364.851
		c15.795-0.084,31.118-3.231,45.592-9.356c21.209-8.98,39.25-23.822,52.154-42.917c13.312-19.656,20.338-42.634,20.311-66.433
		v-64.545C466.256,146.558,437.756,118.054,402.717,118.054z M445.112,246.161c0.019,20.062-6.175,39.057-16.685,54.575
		c-10.507,15.55-25.312,27.852-42.875,35.297c-11.681,4.943-24.59,7.674-37.929,7.674h-0.05h-20.515v147.148H192.382V370.001h-26.54
		h-0.069c-20.315,0-39.559-6.27-55.292-16.923c-15.76-10.66-28.259-25.688-35.803-43.508c-5.023-11.858-7.798-24.982-7.79-38.528
		v-33.224c0-23.416,18.98-42.403,42.396-42.403c23.419,0,42.403,18.987,42.403,42.403v33.224c0.019,3.078,0.859,5.58,2.387,7.89
		c1.535,2.28,17.882,4.137,20.365,5.173c1.673,0.706-10.668,1.098-8.596,1.098h26.54V88.482c0-37.192,30.146-67.338,67.338-67.338
		s67.338,30.146,67.338,67.338v170.426h20.515c2.786-0.008,5.004-0.768,7.087-2.141c2.06-1.382,3.734-3.416,4.667-5.648
		c0.632-1.497,0.986-3.07,0.986-4.958v-64.56c0-23.424,18.98-42.403,42.403-42.403c23.412,0,42.395,18.98,42.395,42.403V246.161z"
      />
      <rect
        x="223.062"
        y="178.531"
        fill="currentColor"
        width="14.098"
        height="21.144"
      />
      <rect
        x="223.062"
        y="389.956"
        fill="currentColor"
        width="14.098"
        height="21.152"
      />
      <rect
        x="223.062"
        y="284.243"
        fill="currentColor"
        width="14.098"
        height="21.144"
      />
      <rect
        x="223.062"
        y="72.819"
        fill="currentColor"
        width="14.098"
        height="21.136"
      />
      <rect
        x="274.752"
        y="234.91"
        fill="currentColor"
        width="14.095"
        height="21.144"
      />
      <rect
        x="274.752"
        y="340.622"
        fill="currentColor"
        width="14.095"
        height="21.152"
      />
      <rect
        x="274.752"
        y="129.198"
        fill="currentColor"
        width="14.095"
        height="21.144"
      />
      <rect
        x="274.752"
        y="446.342"
        fill="currentColor"
        width="14.095"
        height="21.144"
      />
      <path
        fill="currentColor"
        d="M107.748,231.894c0.042-0.721,0.089-1.251,0.116-1.604l0.034-0.376l0.008-0.069l-13.999-1.604
		c-0.027,0.216-0.441,3.891-0.441,9.809c0,3.545,0.153,7.905,0.622,12.802l14.033-1.343c-0.422-4.39-0.56-8.304-0.56-11.458
		C107.56,235.416,107.66,233.306,107.748,231.894z"
      />
      <path
        fill="currentColor"
        d="M138.559,326.454c6.208,3.5,13.224,6.424,21.082,8.596l3.757-13.585c-6.812-1.888-12.732-4.374-17.913-7.291
		L138.559,326.454z"
      />
      <path
        fill="currentColor"
        d="M399.351,171.493l0.054,0.3c0.249,1.589,1.37,9.164,1.888,19.908l14.072-0.675
		c-0.618-13.032-2.076-21.681-2.11-21.889L399.351,171.493z"
      />
      <path
        fill="currentColor"
        d="M374.965,284.842l9.659,10.268c5.737-5.403,10.437-11.558,14.279-18.127l-12.168-7.115
		C383.488,275.425,379.604,280.475,374.965,284.842z"
      />
    </g>
  </Icon>
);
