import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Text,
  SimpleGrid,
  Container,
} from "@chakra-ui/react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ImageBlur, ImageWrapper } from "./styles";

type RecommendedSectionProps = {
  title: string;
  recommended: any[];
};

export const RecommendedSection: React.FC<RecommendedSectionProps> = ({
  title,
  recommended,
}) => {
  if (!recommended || !recommended?.length) {
    return null;
  }

  return (
    <Container maxW="7xl" my={16} px={8}>
      <Heading as="h2" mb={8} fontSize={{ base: "2xl" }} fontWeight="bold">
        {title}
      </Heading>
      <SimpleGrid spacing={4} columns={{ base: 1, md: 3 }}>
        {recommended?.map(({ node }) => {
          let featuredImg = getImage(
            node.frontmatter.featuredimage?.childImageSharp?.gatsbyImageData
          );

          const fallback =
            node.frontmatter.featuredimage?.childImageSharp?.gatsbyImageData
              ?.images?.fallback?.src;
          return (
            <Card as={Link} to={node.fields.slug} key={node.fields.slug}>
              {featuredImg && (
                <ImageWrapper>
                  <GatsbyImage
                    image={featuredImg}
                    objectFit="contain"
                    alt={node.frontmatter.title}
                    style={{
                      borderRadius: "0.125rem",
                      minWidth: "100%",
                      height: "220px",
                      zIndex: 1,
                    }}
                  />
                  <ImageBlur style={{ backgroundImage: `url(${fallback})` }} />
                </ImageWrapper>
              )}
              <CardHeader>
                <Heading size="md">{node.frontmatter.title}</Heading>
              </CardHeader>
              <CardBody>
                <Text>{node.frontmatter.description}</Text>
              </CardBody>
            </Card>
          );
        })}
      </SimpleGrid>
    </Container>
  );
};
