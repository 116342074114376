import React from "react";
import {
  Box,
  chakra,
  Container,
  Flex,
  Grid,
  GridItem,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";

export const AboutSection = () => {
  return (
    <>
      <chakra.section
        bg="brand.100"
        borderBottom="3px solid #d0c57e"
        mt={{ base: 40 }}
      >
        <Container maxW="7xl">
          <Stack
            align="center"
            gap={{ base: 8, md: 10 }}
            py={{ base: 10, md: 14 }}
            px={{ base: 2, md: 4 }}
            direction={{ base: "column-reverse", md: "row" }}
          >
            <Stack flex={1} spacing={{ base: 4, md: 6 }}>
              <Text
                lineHeight={1.5}
                fontWeight={600}
                fontSize={{ base: "lg" }}
                textAlign={{ base: "center", md: "left" }}
                textColor="black"
                as="p"
              >
                Gabinet <strong>Trichodermedica</strong> powstał ponad 3 lata
                temu i wyróżnia się kompleksowym oraz indywidualnym podejściem
                do każdego klienta. Cechuje nas połączenie profesjonalizmu,
                rzetelnej wiedzy oraz doświadczenia.
                <br />
                <br />
                Specjalizujemy się w diagnozowaniu oraz wspomaganiu leczenia
                schorzeń skóry głowy takich jak: nadmierne wypadanie włosów,
                łysienie, łupież, łojotok, łojotokowe zapalenie skóry głowy,
                atopowe zapalenie skóry głowy, sucha i wrażliwa skóra głowy.
              </Text>
            </Stack>
            <Flex
              flex={1}
              justify="center"
              align="center"
              position="relative"
              mt={-24}
              w="fit-content"
            >
              <Box position="relative" rounded="sm" width="fit-content">
                <StaticImage
                  src="../images/front.jpg"
                  alt="Gabinet Trichodermedica"
                  style={{ borderRadius: "0.125rem", zIndex: 1 }}
                  width={600}
                  height={400}
                />
              </Box>
            </Flex>
          </Stack>
        </Container>
      </chakra.section>
      <chakra.section borderBottom="3px solid #d0c57e">
        <Container maxW="7xl" px={{ base: 2, md: 8 }} py={{ base: 10, md: 14 }}>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            gap={{ base: 2, md: 8 }}
            placeItems={{ base: "center", md: "normal" }}
          >
            <GridItem alignItems="center" justifyContent="center">
              <StaticImage
                src="../images/front5.jpg"
                alt="Gabinet Trichodermedica"
                style={{ borderRadius: "0.125rem", zIndex: 1 }}
                height={900}
                width={600}
              />
            </GridItem>
            <GridItem mt={8}>
              <VStack align="center" justify="center">
                <StaticImage
                  src="../images/front2.jpg"
                  alt="Gabinet Trichodermedica"
                  style={{ borderRadius: "0.125rem", zIndex: 1 }}
                  width={600}
                  height={400}
                />
                <Box mt={8}>
                  <Text
                    lineHeight={1.5}
                    fontWeight={600}
                    fontSize={{ base: "lg" }}
                    textAlign={{ base: "center", md: "left" }}
                    textColor="black"
                    as="p"
                  >
                    Oferujemy szeroki zakres trychoterapii m.in. zabiegi
                    oczyszczające skórę głowy, wzmacniające włosy, stymulujące
                    ich odrost, zmniejszające łojotok, przeciwłupieżowe oraz
                    nawilżające suchą skórę głowy.
                  </Text>
                  <Text
                    lineHeight={1.5}
                    fontWeight={600}
                    fontSize={{ base: "lg" }}
                    textAlign={{ base: "center", md: "left" }}
                    textColor="black"
                    as="p"
                    mt={2}
                  >
                    Wykonujemy zabiegi odbudowujące strukturę łodyg włosów m.in.
                    proteinową regenerację, keratynowe prostowanie oraz
                    nanoplastię. Ponadto, w ofercie znajdują się zabiegi z
                    zakresu kosmetologii oraz stylizacja rzęs i brwi.
                  </Text>
                </Box>
              </VStack>
            </GridItem>
          </Grid>
        </Container>
      </chakra.section>
    </>
  );
};
