import * as React from "react";
import { WrapPageElementBrowserArgs } from "gatsby";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fontsource-variable/playfair-display";
import "@fontsource/cardo";
import { Stack } from "@chakra-ui/react";
import { CookieBanner, FixedButtons, NavigationBar } from "./src/components";
import { BooksyIcon, FacebookIcon, InstagramIcon } from "./src/icons";

const MessengerChat = React.lazy(
  () => import("./src/components/MessangerChat")
);

export const wrapPageElement = ({ element }: WrapPageElementBrowserArgs) => {
  return (
    <>
      <NavigationBar />
      {element}
      <FixedButtons>
        <Stack>
          <BooksyIcon />
          <FacebookIcon />
          <InstagramIcon />
          <React.Suspense>
            <MessengerChat />
          </React.Suspense>
        </Stack>
      </FixedButtons>
      <CookieBanner />
    </>
  );
};
