import React from "react";
import { IconButton } from "@chakra-ui/react";

export const BooksyLogo = () => {
  return (
    <svg
      version="1.1"
      id="logo"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 18.5 13.5"
      width={22}
      height={22}
    >
      <path
        id="Shape"
        d="M11.6,4.1c0.7-0.4,1.5-0.6,2.3-0.6c1.7,0.1,3.1,0.8,3.9,2.3c0.5,0.9,0.7,1.8,0.7,2.9c0,1.1-0.3,2.2-1.1,3
    c-0.9,1.1-2.1,1.6-3.6,1.7c-2.1,0.2-3.9-0.7-5.5-1.9C7.6,11,6.8,10.4,5.9,9.9C5.5,9.6,5.1,9.5,4.7,9.3c-1-0.3-1.9,0.1-2.4,1
    c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.2-0.3,0.3-0.5,0.3c-0.4,0-0.8,0-1.2,0c-0.5,0-0.6-0.2-0.4-0.6c0.6-1.4,1.7-2.3,3.2-2.7
    c1.4-0.3,2.7,0,3.8,0.8c0.7,0.5,1.3,1,2,1.5c0.9,0.7,1.9,1.3,3,1.6c1.2,0.3,2.3,0.3,3.4-0.5c0.6-0.4,1-1,1.1-1.8
    c0.2-1,0-1.9-0.6-2.8c-1.2-1.6-3.5-1.5-4.6,0.2c-0.3,0.5-0.5,1-0.5,1.6c0,0.2,0,0.5,0,0.7c0,0.3-0.2,0.4-0.4,0.3
    C10.1,9,9.7,8.7,9.3,8.4C9.1,8.3,9.2,8.1,9.2,7.9c0-1.2,0-2.4,0-3.6c0-1.2,0-2.4,0-3.6c0-0.6,0-0.6,0.6-0.6c0.2,0,0.4,0,0.5,0
    c0.6,0,0.7,0.1,0.7,0.7c0,0.8,0,1.6,0,2.4c0,0.2,0,0.4,0,0.6C11,4.4,11,4.5,11.6,4.1z"
        fill="currentColor"
      />
    </svg>
  );
};

export const BooksyIcon = () => {
  return (
    <IconButton
      icon={<BooksyLogo />}
      aria-label="Booksy logo"
      borderRadius="full"
      color="white"
      p={2}
      as="a"
      href={process.env.GATSBY_BOOKSY_LINK || "#"}
      rel="noreferrer noopener"
    />
  );
};
