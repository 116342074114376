import { IconButton } from "@chakra-ui/react";
import React from "react";

const InstagramLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width="32px"
      height="32px"
    >
      <g>
        <path
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
          d="M30,11H18c-3.9,0-7,3.1-7,7v12c0,3.9,3.1,7,7,7h12c3.9,0,7-3.1,7-7V18C37,14.1,33.9,11,30,11z"
        />
        <circle cx="31" cy="16" r="1" fill="#fff" />
      </g>
      <g>
        <circle
          cx="24"
          cy="24"
          r="6"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export const InstagramIcon = () => {
  return (
    <IconButton
      icon={<InstagramLogo />}
      aria-label="Instagram logo"
      borderRadius="full"
      background="radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)"
      _hover={{
        filter: "brightness(90%)",
      }}
      as="a"
      href={process.env.GATSBY_INSTAGRAM_LINK || "#"}
      rel="noreferrer noopener"
    />
  );
};
