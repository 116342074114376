import React from "react";

export const Logo = () => {
  return (
    <svg
      width="221"
      height="65"
      viewBox="0 0 221 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_85_779)">
        <path
          d="M220.6 33.3V34.4H219.7C156.1 34.4 92.5 34.3 29 34.3C26.9 34.3 25.4 35.7 25.2 37.9C25 39.4 25.3 40.8 25.9 42.2C27 44.8 28.2 47.4 29.3 50C31.9 56.3 27.7 62.2 22.8 63.7C18.6 65 13.2 62.5 11.2 58.2C9.6 54.9 9.9 51.6 11.3 48.3C12.4 45.8 13.4 43.3 14.4 40.8C15.7 37.3 13.7 34.4 9.8 34.4C6.9 34.3 3.9 34.4 1 34.4H0V33.3H10.6C14.3 33.3 17 36.8 15.9 40.3C15.1 42.8 14 45.2 13 47.7C12.6 48.8 12.2 49.8 11.8 50.9C10 55.8 13.3 62.8 20.5 63.1C23.6 63.2 25.9 61.5 27.6 59C29.5 56.2 29.5 53.3 28.2 50.3C27.1 47.8 26 45.3 24.9 42.8C23.9 40.5 23.8 38.1 24.9 35.8C25.6 34.2 26.7 33.3 28.6 33.3H220.6Z"
          fill="currentColor"
        />
        <path
          d="M35.5 0C33.7 3 31.9 6 30.2 9C27.7 13.4 25.3 17.8 24.3 22.8C22.4 31 21.8 39.3 23.7 47.5C23.9 48.5 24.4 49.6 25 50.4C26.7 52.6 26.5 55.2 25.4 56.9C24 59 21.5 60.1 19.1 59.5C18.3 59.3 17.4 59 16.7 58.5C14.2 57 13.2 54.1 14.8 51.7C16.3 49.5 16.6 47.1 16.8 44.6C17.6 36.8 19.1 29.2 21.4 21.7C22.8 16.9 25.4 12.8 28.5 8.9C30.8 5.9 33.1 2.9 35.5 0ZM17.2 53.7C16.9 55 17.3 56.2 18.4 56.9C19.5 57.6 20.8 57.6 21.9 56.9C22.9 56.2 23.3 55 23 53.9C20.6 56.9 18.9 56.8 17.2 53.7Z"
          fill="currentColor"
        />
        <path
          d="M153.3 23.4C154.2 20.7 155.1 18.2 155.9 15.7C156.2 14.9 156.5 14.5 157.4 14.6C158.4 14.7 159.4 14.6 160.4 14.6C160.6 14.6 160.8 14.8 161 14.8C160.8 15 160.7 15.2 160.5 15.2C159.8 15.4 159.5 15.9 159.5 16.5V25.7C159.5 26.4 159.8 26.9 160.5 27C160.7 27 160.8 27.3 160.9 27.4C160.7 27.5 160.6 27.7 160.4 27.7H155.6C155.4 27.7 155.3 27.5 155.1 27.4C155.2 27.2 155.3 27 155.5 27C156.3 26.9 156.6 26.4 156.7 25.7C156.7 22.7 156.7 19.6 156.6 16.5C156.5 16.7 156.4 16.9 156.3 17.1C155.2 20.4 154.1 23.7 153 26.9C152.9 27.2 152.5 27.4 152.3 27.7C152.1 27.4 151.8 27.2 151.7 26.9C150.6 23.7 149.5 20.4 148.4 17.2C148.3 17 148.3 16.8 148.1 16.4C148 16.7 148 16.8 148 16.9V25.4C148 26.2 148.2 26.8 149.1 27C149.3 27 149.4 27.3 149.5 27.4C149.3 27.5 149.1 27.7 149 27.7H145.3C145.6 27.3 145.7 27 145.9 27C146.7 26.8 146.9 26.2 146.9 25.5V16.7C146.9 16 146.7 15.4 145.8 15.2C145.7 15.2 145.6 14.9 145.4 14.8C145.5 14.7 145.7 14.5 145.8 14.5H149.7C149.9 14.5 150.3 14.9 150.4 15.1C151.3 17.6 152.1 20.1 153 22.7C153.2 22.9 153.2 23 153.3 23.4Z"
          fill="currentColor"
        />
        <path
          d="M85.5 27.5C85.7 27.3 85.9 27.2 86.1 27C86.4 26.7 87 26.3 87 26C87.1 24.5 87 23 87 21.4H82.9C82.7 21.4 82.5 21.7 82.5 21.9V25.9C82.5 26.5 82.8 26.9 83.5 27.1C83.7 27.2 83.9 27.4 84.1 27.6C84.1 27.7 84 27.8 84 27.9H78.4L78.2 27.7C78.4 27.5 78.5 27.2 78.7 27.1C79.5 26.9 79.7 26.4 79.7 25.7V16.8C79.7 16 79.5 15.5 78.6 15.3C78.5 15.3 78.4 15.1 78.2 14.9C78.3 14.8 78.5 14.6 78.6 14.6H83.9C84 14.7 84 14.8 84.1 14.9C83.9 15.1 83.8 15.3 83.6 15.3C83 15.4 82.6 15.7 82.6 16.4V20.3H87.1C87.1 19 87.2 17.6 87.1 16.3C87.1 15.9 86.5 15.6 86.2 15.3C86 15.1 85.9 15 85.7 14.9C85.9 14.8 86.1 14.6 86.2 14.6H90.9C91.1 14.6 91.3 14.8 91.5 14.8C91.3 15 91.2 15.2 91 15.3C90.2 15.5 90 16 90 16.7V25.6C90 26.3 90.2 26.9 91.1 27.1C91.3 27.1 91.4 27.4 91.5 27.6C91.3 27.7 91.2 27.9 91 27.9H85.8C85.6 27.7 85.5 27.6 85.5 27.5Z"
          fill="currentColor"
        />
        <path
          d="M141.1 21.7C141.9 23.1 142.9 24.8 143.9 26.5C144.1 26.8 144.5 26.9 144.7 27.1C144.8 27.2 144.9 27.4 145 27.5C144.9 27.6 144.7 27.8 144.6 27.8H141.7C141.5 27.8 141.2 27.5 141 27.2C140.1 25.6 139.2 24 138.4 22.4C138.1 21.8 137.7 21.7 137.1 21.9C137.1 23.3 137 24.7 137.1 26.1C137.1 26.4 137.7 26.7 138 27C138.1 27.1 138.3 27.3 138.4 27.4C138.2 27.5 138 27.7 137.8 27.7H133.3C133.1 27.7 132.9 27.5 132.7 27.4C132.8 27.3 133 27 133.1 27C133.9 26.8 134.1 26.3 134.1 25.6V16.7C134.1 16 133.9 15.4 133 15.2C132.9 15.2 132.8 14.9 132.6 14.8C132.8 14.7 132.9 14.5 133.1 14.5C135.4 14.5 137.7 14.4 140 14.6C142.6 14.8 144.1 16.9 143.1 19.2C142.8 20.1 141.9 20.8 141.1 21.7ZM137.1 20.8C138.4 20.9 139.4 20.7 140 19.6C140.5 18.7 140.4 17.1 139.8 16.3C139.1 15.4 138.1 15.3 137.1 15.5V20.8Z"
          fill="currentColor"
        />
        <path
          d="M53.7 21.4C54.6 23 55.5 24.6 56.4 26.2C56.6 26.6 56.9999 26.8 57.2999 27.1C57.4999 27.3 57.6 27.5 57.9 27.9H54.4C54.1 27.9 53.8 27.6 53.7 27.3C52.8 25.7 52 24.2 51.2 22.6C50.9 22 50.4999 21.8 49.7999 22C49.7999 23.3 49.6999 24.7 49.7999 26.1C49.7999 26.4 50.2999 26.8 50.5999 27.1C50.7999 27.3 51 27.5 51.2 27.7C51.1333 27.7667 51.0666 27.8333 51 27.9H45.2C45.5 27.5 45.5999 27.2 45.7999 27.2C46.4999 27 46.7999 26.6 46.7999 25.9V16.7C46.7999 16 46.4999 15.6 45.7999 15.4C45.5999 15.4 45.3999 15.1 45.2999 15C45.2999 14.9 45.4 14.9 45.4 14.8C45.6 14.8 45.7999 14.7 46.0999 14.7H51.7C52.4 14.7 53.1 14.8 53.7 15C55 15.4 55.8999 16.2 56.0999 17.6C56.2999 19.1 55.8 20.3 54.4 21.1C54.3 21.1 54.1 21.2 53.7 21.4ZM49.7999 20.8C50.9999 20.9 52.1 20.8 52.7 19.7C53.3 18.8 53.1999 17.2 52.5999 16.4C51.8999 15.5 50.8999 15.4 49.7999 15.6C49.7999 17.3 49.7999 19 49.7999 20.8Z"
          fill="currentColor"
        />
        <path
          d="M177.9 14.6H180.5C184.3 14.7 186.6 17.1 186.7 20.8C186.8 24.6 184.6 27.6 180.8 27.7C179.1 27.7 177.3 27.8 175.6 27.8H174.5C174.3 27.8 174.2 27.6 174 27.5C174.1 27.3 174.2 27.1 174.3 27.1C175.4 26.9 175.5 26.1 175.5 25.2V17.1C175.5 16.2 175.5 15.4 174.3 15.2C174.2 15.2 174.1 15 174 14.8C174.2 14.7 174.3 14.5 174.5 14.5C175.7 14.6 176.8 14.6 177.9 14.6ZM178.4 15.6V25.5C178.5 26.5 178.8 26.8 179.8 26.7C181.4 26.6 182.5 25.9 183.1 24.4C183.8 22.6 183.8 20.7 183.4 18.8C183 16.3 181.3 15.1 178.4 15.6Z"
          fill="currentColor"
        />
        <path
          d="M106.6 27.7C106.8 27.5 106.9 27.2 107.1 27.1C107.9 26.9 108.1 26.3 108.1 25.6V16.9C108.1 16.1 107.9 15.5 107 15.3C106.9 15.3 106.8 15 106.6 14.9C106.7 14.8 106.9 14.6 107 14.6C109.2 14.6 111.5 14.5 113.7 14.7C116.6 14.9 118.4 16.7 119 19.6C119.3 21.3 119.2 23 118.4 24.6C117.5 26.3 116.1 27.5 114.2 27.6C111.9 27.8 109.4 27.7 106.6 27.7ZM111 15.5V25.8C111 26.4 111.4 26.7 112 26.8C113.7 26.9 115.2 26 115.7 24.4C116.4 22.3 116.4 20.1 115.7 18C115.2 16.5 114.1 15.7 112.5 15.6C112.1 15.5 111.6 15.5 111 15.5Z"
          fill="currentColor"
        />
        <path
          d="M105 21.2C105 23.2 104.4 25 103 26.5C100.7 28.8 96.9999 28.7 94.8999 26.3C92.5999 23.7 92.3999 19.3 94.4999 16.6C97.0999 13.2 101.8 13.6 104 17.3C104.7 18.5 105.1 19.8 105 21.2ZM102 21.2H101.9C101.9 20.6 101.9 19.9 101.8 19.3C101.6 18.3 101.4 17.3 100.9 16.4C99.9999 14.7 97.9999 14.7 97.0999 16.4C96.8999 16.8 96.6999 17.2 96.5999 17.7C96.0999 19.7 95.9999 21.7 96.3999 23.6C96.5999 24.6 96.9999 25.6 97.4999 26.4C98.2999 27.6 99.8999 27.5 100.7 26.4C101.1 25.9 101.4 25.3 101.5 24.8C101.7 23.6 101.8 22.4 102 21.2Z"
          fill="currentColor"
        />
        <path
          d="M131 14.6V17.1C131 17.2 130.8 17.4 130.7 17.4C130.6 17.4 130.3 17.3 130.3 17.2C130 15.6 128.9 15.5 127.6 15.5H125.3V20.4C125.9 20.4 126.6 20.5 127.2 20.2C127.6 20 127.8 19.4 128.1 19C128.2 18.9 128.3 18.7 128.4 18.6C128.5 18.8 128.7 18.9 128.7 19.1V22.7C128.7 22.9 128.5 23 128.4 23.2C128.3 23.1 128.1 23 128.1 22.9C127.5 21.3 127.2 21.2 125.2 21.5C125.2 23 125.1 24.5 125.2 25.9C125.2 26.2 125.8 26.7 126.1 26.7C127 26.8 127.9 26.8 128.8 26.7C129.8 26.6 130.3 25.8 130.7 25C130.8 24.7 131 24.6 131.2 24.3C131.3 24.4 131.4 24.4 131.5 24.5C131.4 25.5 131.2 26.5 131 27.4C131 27.6 130.7 27.8 130.5 27.8H121.3C121.1 27.8 121 27.6 120.8 27.5C120.9 27.4 121 27.1 121.2 27.1C122.2 26.9 122.3 26.2 122.3 25.4V16.8C122.3 16.1 122.2 15.4 121.3 15.2C121.1 15.2 121 14.9 120.9 14.8C121.1 14.7 121.3 14.6 121.5 14.6H126.3C127.9 14.6 129.4 14.6 131 14.6Z"
          fill="currentColor"
        />
        <path
          d="M172.4 14.6V17C172.4 17.1 172.2 17.3 172.1 17.4C172 17.3 171.8 17.2 171.7 17C171.1 15.6 170.9 15.4 169.4 15.4H166.9C166.833 15.4 166.767 15.4333 166.7 15.5V20.3H168C168.8 20.3 169.4 19.9 169.5 19C169.5 18.8 169.7 18.7 169.8 18.5C169.9 18.7 170.1 18.8 170.1 19V22.6C170.1 22.8 169.9 22.9 169.8 23.1C169.7 23 169.5 22.9 169.5 22.8C168.9 21.2 168.6 21.1 166.7 21.4V26C166.7 26.3 167.3 26.6 167.6 26.7C168.4 26.8 169.2 26.8 170.1 26.7C171.2 26.6 171.8 25.9 172.2 24.9C172.3 24.6 172.5 24.4 172.7 24.2C172.8 24.3 172.9 24.3 173 24.4C172.8 25.5 172.7 26.6 172.5 27.8H163.1C162.8 27.8 162.6 27.6 162.3 27.5C162.5 27.3 162.8 27.2 162.9 26.9C163.2 26.5 163.7 26.1 163.7 25.7C163.8 22.6 163.7 19.5 163.7 16.4C163.7 15.7 163.4 15.2 162.6 15.1C162.5 15.1 162.4 14.9 162.2 14.7C162.3 14.6 162.5 14.4 162.6 14.4C166 14.6 169.2 14.6 172.4 14.6Z"
          fill="currentColor"
        />
        <path
          d="M214.8 27.6C215 27.4 215.2 27.2 215.4 27.1C216.1 26.9 216.3 26.5 216.1 25.8C216.1 25.7 216 25.6 216 25.6C215.6 24.3333 214.733 23.7 213.4 23.7H212.4C211.8 23.7 211 23.6 210.7 23.9C210.3 24.5 210.1 25.3 210 26C209.9 26.6 210.2 27 210.8 27.1C211 27.2 211.1 27.4 211.2 27.6C211 27.7 210.8 27.9 210.6 27.9H207.1C207.2 27.6 207.3 27.2 207.4 27.2C208.2 26.9 208.5 26.1 208.8 25.4C210.1 22.1 211.4 18.8 212.6 15.5C212.9 14.7 213.4 14.8 213.9 14.8C214.5 14.8 214.9 14.9 215.1 15.6C216.3 18.9 217.6 22.3 218.8 25.6C219.1 26.3 219.3 27.1 220.2 27.4C220.3 27.4 220.5 27.7 220.4 27.9C220.4 28 220.1 28.2 220 28.2H214.6C214.9 27.7 214.8 27.6 214.8 27.6ZM215 22.4C214.4 20.6 213.8 18.9 213.2 17C212.5 18.9 211.9 20.6 211.2 22.4H215Z"
          fill="currentColor"
        />
        <path
          d="M76.3999 18.5C75.9999 18.2 75.7999 18.1 75.5999 17.9C75.5999 17.9 75.5999 17.8 75.4999 17.7C74.6999 15.9 73.5999 15.1 72.1999 15.2C70.3999 15.4 69.6999 16.7 69.2999 18.1C68.6999 20.1 68.6999 22.2 69.4999 24.3C70.4999 27 72.9999 27.5 75.0999 25.6C75.4999 25.2 75.8999 24.8 76.1999 24.4C76.7999 24.7 76.7999 25.1 76.3999 25.6C73.6999 29.3 68.8999 29 66.6999 25C65.1999 22.2 65.5999 18.4 67.8999 16.1C69.5999 14.3 71.6999 13.9 73.9999 14.8C74.4999 15 75.0999 15.6 75.4999 14.6C75.6999 14.7 76.0999 14.8 76.0999 14.9C76.2999 16 76.3999 17.1 76.3999 18.5Z"
          fill="currentColor"
        />
        <path
          d="M206.4 24.4C206.5 24.5 206.7 24.7 206.9 24.9C205.8 26.6 204.5 28 202.4 28.1C200.1 28.2 198.4 27.2 197.1 25.4C195.1 22.4 195.7 17.8 198.5 15.5C200.2 14.1 202.1 13.8 204.1 14.6C204.6 14.8 205.2 15.4 205.7 14.4C205.9 14.1 206.3 14.3 206.3 14.7C206.4 15.8 206.4 17 206.3 18.3C206.1 18.1 205.8 18 205.7 17.8C205.4 17.3 205.2 16.7 204.8 16.3C203.4 14.7 201.2 14.8 200.1 16.6C198.5 19.4 198.6 22.4 200 25.2C200.8 26.8 202.7 27.2 204.2 26.2C205.1 25.8 205.7 25.1 206.4 24.4Z"
          fill="currentColor"
        />
        <path
          d="M35.9999 27.5C36.1999 27.3 36.4999 27.2 36.5999 27C36.8999 26.7 37.4999 26.3 37.4999 26C37.5999 22.6 37.4999 19.1 37.4999 15.7C35.6999 15.3 34.7999 15.8 34.1999 17.4C34.0999 17.6 33.8999 17.7 33.6999 17.9C33.5999 17.7 33.3999 17.5 33.3999 17.4C33.3999 16.6 33.3999 15.9 33.4999 15.1C33.4999 15 33.4999 14.9 33.5999 14.7H44.1999C44.2999 15.7 44.2999 16.8 44.3999 18.1C43.9999 17.8 43.5999 17.7 43.5999 17.5C42.8999 15.8 42.3999 15.5 40.3999 15.7C40.3999 15.9 40.2999 16.1 40.2999 16.4V25.4C40.2999 26.3 40.3999 27 41.4999 27.2C41.5999 27.2 41.6999 27.5 41.8999 27.7C41.6999 27.8 41.5999 28 41.3999 28H36.0999C36.0999 27.7 35.9999 27.6 35.9999 27.5Z"
          fill="currentColor"
        />
        <path
          d="M58.4001 14.6H63.8C64 14.6 64.1001 14.8 64.3001 14.9C64.2001 15 64.0001 15.3 63.9001 15.3C63.0001 15.4 62.8 16 62.8 16.8V25.7C62.8 26.8 63.4 27.1 64.6 27.3C64.3 27.6 64.1 27.9 64 27.9H58.6C58.5 27.8 58.5001 27.7 58.4001 27.7C58.6001 27.5 58.7001 27.2 58.9001 27.2C59.7001 27 60 26.5 60 25.8V16.8C60 16.1 59.7001 15.6 58.9001 15.4C58.7001 15.4 58.5 15.1 58.3 15C58.3 14.8 58.3001 14.7 58.4001 14.6Z"
          fill="currentColor"
        />
        <path
          d="M194.2 27.8H188.6C188.533 27.7333 188.467 27.6667 188.4 27.6C188.6 27.4 188.8 27.1 189 27C189.7 26.8 190 26.4 190 25.7V16.5C190 15.8 189.7 15.4 188.9 15.2C188.7 15.2 188.6 14.9 188.4 14.7C188.4 14.6 188.5 14.5 188.5 14.5H193.9C194 14.5 194.1 14.7 194.3 14.8C194.2 14.9 194.1 15.2 194 15.2C193.1 15.3 192.8 16 192.8 16.8V25.4C192.8 26.2 193 26.8 193.9 27C194.1 27 194.2 27.3 194.4 27.5C194.3 27.7 194.2 27.7 194.2 27.8Z"
          fill="currentColor"
        />
        <path
          d="M138.6 41.6V41.8C137.733 42.3333 137.5 43.0667 137.9 44C138.5 45.6 139.1 47.1 139.8 48.8C140 48.4 140.1 48.1 140.2 47.8C140.8 46.4 141.4 44.9 141.9 43.5C142.3 42.4 142.3 42.3 141.2 41.8C141.1 41.8 141.1 41.7 141 41.5H144.2V41.7C143.2 42.1 142.9 43.1 142.6 44C141.8 46.1 140.9 48.1 140 50.2C139.9 50.4 139.7 50.6 139.5 50.8C139.3 50.6 139.1 50.4 139 50.2C138.3 48.6 137.7 47 137 45.2C136.4 46.6 135.9 47.8 135.4 49.1C135.2 49.5 135.1 49.9 134.9 50.3C134.8 50.5 134.6 50.7 134.4 50.8C134.2 50.6 134 50.5 133.9 50.3C133 48.3 132.3 46.2 131.4 44.2C131 43.3 130.4 42.4 129.9 41.5H133.6C133.3 41.7 133.2 41.8 133 41.9C132.3 42 132.1 42.4 132.4 43.1C133.1 44.9 133.9 46.8 134.7 48.9C135.4 47.3 136.1 45.9 136.4 44.4C136.5 43.7 135.7 42.9 135.2 42.1C135.1 41.9 134.8 41.9 134.7 41.6C136 41.6 137.3 41.6 138.6 41.6Z"
          fill="currentColor"
        />
        <path
          d="M92.5999 50.8H89.3999C91.6999 48.3 92.3999 45.1 93.9999 42.3C94.1999 42 94.3999 41.8 94.5999 41.5C94.7999 41.8 95.0999 42 95.2999 42.3C96.1999 44.1 96.9999 45.9 97.7999 47.7C98.3999 48.9667 99.1999 50 100.2 50.8H95.7999C95.7999 50.7 95.7999 50.6 95.8999 50.6C96.2999 50.3 96.8999 50.1 96.8999 49.9C96.7999 49.3 96.5999 48.1 96.2999 48.1C94.9999 47.9 93.5999 47.9 92.1999 48.1C91.8999 48.1 91.6999 49.2 91.5999 49.8C91.5999 49.9 91.8999 50.2 92.0999 50.3L92.6999 50.6C92.6999 50.6 92.5999 50.7 92.5999 50.8ZM92.5999 47H95.8999C95.3999 45.8 94.8999 44.6 94.2999 43.3C93.6999 44.6 93.1999 45.7 92.5999 47Z"
          fill="currentColor"
        />
        <path
          d="M156.4 50.6C157.3 50.5 157.3 49.9 157.3 49.2V45.5C157.3 44.8 157.3 44.2 156.5 43.9H159.4C157.6 44.7 158.7 46.2 158.4 47.3C158.5 47.3 158.5 47.4 158.6 47.4C159.5 46.6 160.6 45.8 161.4 44.8C162 44.1 160.9 44.3 160.7 43.8H163.7C162.6 44.6 161.6 45.5 160.4 46.4C161.6 47.8 162.1 49.6 164 50.7H160.7V50.6C160.9 50.5 161.1 50.4 161.5 50.2C160.9 49.2 160.3 48.3 159.6 47.3C158 48.2 157.8 49.7 159.3 50.7H156.4C156.5 50.7 156.4 50.7 156.4 50.6Z"
          fill="currentColor"
        />
        <path
          d="M71 46.7C71.3 46.5 71.5 46.2 71.8 46.1C73 45.4 74.1 46 74.2 47.4V49.3C74.3 49.8 74.5 50.4 74.6 50.9H72.7C72.9 50.4 73.1 49.9 73.1 49.4C73.2 48.9 73.1 48.3 73.1 47.8C73.1 47.2 73 46.7 72.3 46.6C71.7 46.5 70.9 47.1 70.9 47.7C70.8 48.5 70.8 49.3 70.9 50.1C70.9 50.4 71.3 50.6 71.5 50.9H69C69.9 50.5 69.9 49.9 69.9 49.3V47.8C69.9 47.2 69.8 46.7 69.1 46.6C68.4 46.6 67.9 47 67.8 47.6C67.7 48.4 67.7 49.2 67.8 50C67.8 50.3 68.2 50.5 68.4 50.9H66.1C67.1 50.1 67.1 47.1 66 46.3C66.6 46.1 67.1 46 67.7 45.8C67.7 46.1 67.8 46.3 67.8 46.6C69.4 45.4 70 45.4 71 46.7Z"
          fill="currentColor"
        />
        <path
          d="M106.3 43.9H108.6C107.5 44.4 107.8 45.3 107.8 46.1V50.9C107.7 51 107.6 51 107.5 51.1C105.9 49.2 104.2 47.2 102.4 45.1V49.9C102.4 50.2 102.9 50.5 103.2 50.8H100.9C101.9 50.4 101.8 49.6 101.8 48.8V45.5C101.8 44.8 101.8 44.1 100.9 44C101.9 43.5 102.7 43.6 103.3 44.4C104.3 45.6 105.4 46.9 106.4 48.1C106.6 48.3 106.8 48.5 107.1 48.9C107.1 47.4 107.2 46.1 107.1 44.9C107 44.6 106.6 44.2 106.3 43.9Z"
          fill="currentColor"
        />
        <path
          d="M115.2 43.9H117.7C116.7 44.2 116.9 45 116.9 45.8V51C116.8 51.1 116.7 51.1 116.6 51.2C115 49.3 113.3 47.3 111.6 45.2V50C111.6 50.3 112.1 50.6 112.4 50.9H110.1C110.9 50.5 110.9 49.9 110.9 49.2V45.9C110.9 45.1 111 44.4 109.9 44.2C111.1 43.7 111.8 43.9 112.5 44.7C113.7 46.1 114.9 47.5 116.2 49.2V44.9C116 44.5 115.5 44.2 115.2 43.9Z"
          fill="currentColor"
        />
        <path
          d="M76.9 50.8C76.2 50.1 76.2 49.9 77.1 48.8C76.4 48.3 76 47.7 76.4 46.8C76.8 46.1 77.7 45.6 78.7 45.8C79.2 45.9 79.7 46 80.1 45.5C80.2 45.4 80.5 45.3 80.7 45.4C81 45.6 81.3 45.8 81.5 46.1C81.5 46.1 81.2 46.5 81.1 46.7C80.9 46.5 80.6 46.3 80.3 46L79.9 46.2C79.9666 46.3333 80.0333 46.4333 80.1 46.5C80.2 47 80.5 47.7 80.3 48.1C80.1 48.8 79.3 49.1 78.6 49.1C78 49.1 77.5 49 77.1 49.9C77.6 50 78 50.1 78.4 50.1C79 50.1 79.6 50.1 80.1 50.2C80.5 50.3 81 50.6 81.1 51C81.2 51.4 81.1 52.1 80.8 52.4C80 53.5 77.6 53.7 76.5 52.9C75.7 52.3 75.8 51.6 76.9 50.8ZM78.6 50.9C78.5 50.9 78.1 50.8 77.7 51C77.4 51.2 77 51.5 77 51.8C77 52 77.4 52.5 77.6 52.6C78.2 52.7 79 52.7 79.6 52.5C80 52.4 80.2 51.8 80.4 51.4C80 51.2 79.7 51 79.3 50.9H78.6ZM79.4 47.5C79.4 46.7 79 46.1 78.4 46.1C77.8 46.1 77.3 46.6 77.3 47.3C77.3 48 77.6 48.5 78.3 48.5C79.1 48.5 79.4 48.1 79.4 47.5Z"
          fill="currentColor"
        />
        <path
          d="M185 44.3C183.4 44.6 181.8 43.7 180.9 45.6C180.4 44.3 180.7 43.9 181.9 43.9H186.7C185 46.2 183.5 48.2 182 50.3C183.8 50.9 185.6 50.4 186.8 49.1C186.7 49.7 186.7 50.3 186.6 51H180.3C181.8 48.6 183.3 46.5 185 44.3Z"
          fill="currentColor"
        />
        <path
          d="M126.2 50.8H123.5C123.7 50.4 124.1 50 124 49.7C123.9 49 123.5 48.4 122.6 48.6C122.2 48.7 121.8 48.7 121.5 48.6C120.5 48.4 120.2 49.1 120.1 49.8C120.1 50.1 120.5 50.5 120.7 50.9H118.4C120 49.1 120.6 46.7 121.8 44.7C122 44.4 122.1 44.1 122.4 43.7C122.6 44.1 122.8 44.4 122.9 44.6C123.6 46.1 124.3 47.6 125 49.1C125.3 49.7 125.8 50.2 126.2 50.8ZM123.2 47.9C122.8 47 122.5 46.2 122 45.2C121.5 46.2 121.1 47 120.7 47.9H123.2Z"
          fill="currentColor"
        />
        <path
          d="M178.3 43.9C178.3 44.4 178.3 45 178.4 45.6C178.3 45.6 178.3 45.7 178.2 45.7C178.1 45.5 177.9 45.3 177.8 45.1C177.3 44.3 176.2 43.9 175.3 44.3C175 44.5 174.6 44.9 174.6 45.2C174.6 45.5 174.9 46 175.1 46.2C175.7 46.5 176.4 46.7 177 47C178.4 47.6 179 48.3 178.7 49.5C178.5 50.5 177.3 51.3 176 51.2C175.6 51.2 175.2 51 174.8 51H173.4C173.4 50.4 173.3 49.7 173.3 49H173.5C173.7 49.3 173.9 49.6 174.1 49.9C174.7 50.7 175.7 51.1 176.6 50.7C177 50.5 177.5 50 177.6 49.6C177.6 49.2 177.2 48.7 176.9 48.4C176.4 48 175.7 47.9 175.1 47.7C173.8 47.2 173.3 46.5 173.5 45.6C173.7 44.6 174.6 43.9 175.9 44C176.2 44 176.5 44.1 176.8 44.1C177.2 43.8 177.7 43.9 178.3 43.9Z"
          fill="currentColor"
        />
        <path
          d="M169.6 43.9H171.9C171 44.3 171 45 171 45.8V48.7C170.9 50.1 170.2 50.9 168.8 51C168.2 51.1 167.5 51 166.9 50.9C165.8 50.7 165.2 49.8 165.2 48.5V45.5C165.2 44.8 165.2 44.2 164.3 43.9H167.1C165.9 44.3 166.2 45.3 166.2 46.1V48.5C166.3 49.8 166.9 50.4 168.2 50.5C169.6 50.5 170.3 49.9 170.4 48.6C170.5 47.4 170.5 46.2 170.4 45C170.4 44.6 169.9 44.3 169.6 43.9Z"
          fill="currentColor"
        />
        <path
          d="M149.3 50.6C150.3 50.5 150.3 49.9 150.3 49.2V45.6C150.3 44.9 150.3 44.3 149.4 43.9H152.3C151 44.2 151.3 45.2 151.3 46.1V49.4C151.3 50 151.5 50.3 152.2 50.3C153.4 50.3 154.7 50.5 155.2 48.8C155.6 50.2 155.2 50.8 154 50.8H149.5C149.4 50.8 149.4 50.7 149.3 50.6Z"
          fill="currentColor"
        />
        <path
          d="M145.2 43.9H148.1C147 44.2 147.2 45 147.2 45.8V49C147.2 49.8 147.1 50.6 148.1 50.9H145.2C146.4 50.5 146.1 49.5 146.1 48.7V45.9C146.1 45.1 146.3 44.2 145.2 43.9Z"
          fill="currentColor"
        />
        <path
          d="M85.1 50.8H82.7C83.8 50 83.8 46.9 82.7 46.2C83.3 46 83.8 45.9 84.4 45.8C84.5 46.1 84.5 46.4 84.5 46.6C84.6 46.6 84.6 46.5 84.7 46.5C85 46.3 85.3 45.9 85.7 45.8C86 45.7 86.4 46 86.7 46.1C86.8 46.1 86.6 46.6 86.5 46.8C86.2 46.7 85.8 46.6 85.5 46.5V46.3C84.8 46.5 84.5 47.1 84.4 47.7C84.3 48.4 84.3 49.1 84.4 49.8C84.4 50.2 84.8 50.5 85.1 50.8Z"
          fill="currentColor"
        />
        <path
          d="M17.1999 53.7C18.8999 56.8 20.5999 56.8 22.9999 53.9C23.2999 55 22.7999 56.2 21.8999 56.9C20.7999 57.6 19.4999 57.7 18.3999 56.9C17.2999 56.2 16.8999 55 17.1999 53.7Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_85_779">
          <rect width="220.6" height="64.04" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
