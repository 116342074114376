import { HamburgerIcon } from "@chakra-ui/icons";
import { Link } from "gatsby";
import {
  useDisclosure,
  Flex,
  Button,
  Drawer,
  VStack,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  DrawerBody,
} from "@chakra-ui/react";
import React from "react";
import Wave from "../assets/wave.svg";

const menu = [
  {
    label: "Strona główna",
    href: "/",
  },
  {
    label: "O mnie",
    href: "/o-mnie",
  },
  {
    label: "Trychologia",
    href: "/trychologia",
  },
  {
    label: "Kosmetologia",
    href: "/kosmetologia",
  },
  {
    label: "Stylizacja rzęs i brwi",
    href: "/stylizacja-rzes-i-brwi",
  },
  {
    label: "Stylizacja paznokci",
    href: "/stylizacja-paznokci",
  },
  {
    label: "Masaże",
    href: "/masaze",
  },
  {
    label: "Strefa Męska",
    href: "/strefa-meska",
  },
  {
    label: "Cennik",
    href: "/cennik",
  },
  {
    label: "Efekty zabiegów",
    href: "/efekty-zabiegow",
  },
  {
    label: "Karta podarunkowa",
    href: "/bon-podarunkowy",
  },
  {
    label: "Kontakt",
    href: "/kontakt",
  },
];

export const NavigationMenu = () => {
  const { isOpen, onClose, onToggle } = useDisclosure();
  const btnRef = React.useRef<HTMLButtonElement | null>(null);

  return (
    <div>
      <Flex>
        <Button
          ref={btnRef}
          onClick={onToggle}
          variant="ghost"
          aria-label="Menu Toggle"
        >
          <HamburgerIcon />
        </Button>
        <Drawer
          useInert
          isOpen={isOpen}
          onClose={onClose}
          finalFocusRef={btnRef}
          placement="left"
        >
          <DrawerOverlay />
          <DrawerContent pos="relative">
            <DrawerBody>
              <DrawerCloseButton onClick={onClose} />
              <VStack alignItems="left" mt={24} gap={4} fontWeight="bold">
                {menu.map((item) => (
                  <Link
                    key={item.href}
                    to={item.href}
                    activeStyle={{ textDecoration: "underline" }}
                    onClick={onClose}
                  >
                    {item.label}
                  </Link>
                ))}
              </VStack>
            </DrawerBody>
            <Wave
              style={{
                position: "absolute",
                height: "5rem",
                bottom: 0,
                left: 0,
                right: 0,
                width: "100%",
              }}
            />
          </DrawerContent>
        </Drawer>
      </Flex>
    </div>
  );
};
