import React, { useState } from "react";
import {
  Button,
  Container,
  Flex,
  HStack,
  Text,
  Stack,
  chakra,
  Box,
  Heading,
  IconButton,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import Slider from "react-slick";
import { CarouselContainer } from "./styles";
import { ArrowBackIcon, ArrowForwardIcon, SearchIcon } from "@chakra-ui/icons";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const settings = {
  dots: false,
  arrows: false,
  fade: false,
  infinite: true,
  autoplay: false,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 2,
  slidesToScroll: 1,
  variableWidth: true,
  adaptiveHeight: true,
};

const cards = Array.from({ length: 7 }, (_, i) => i + 1);

const LastSliderCard = () => (
  <Box
    w={{ base: "240px", md: "400px" }}
    h={{ base: "240px", md: "400px" }}
    borderColor="brand.100"
    borderRadius="sm"
    borderWidth={1}
    borderStyle="solid"
    display="flex"
    alignItems="center"
    justifyContent="center"
    cursor="pointer"
    transition="0.3s ease-in-out"
    _hover={{
      bg: "brand.50",
      transition: "0.3s ease-in-out",
    }}
    as={Link}
    to="/trychologia"
  >
    <VStack gap={4}>
      <SearchIcon w={20} h={20} color="brand.300" />
      <Text textColor="brand.300" fontFamily="heading">
        Przejdź do artykułów
      </Text>
    </VStack>
  </Box>
);

export const ProblemSection: React.FC<{
  data: { allMdx: { edges: any[] } }[];
}> = ({ data }) => {
  const [slider, setSlider] = useState<Slider | null>(null);

  const targetCardWidth =
    useBreakpointValue(
      {
        base: 240,
        md: 400,
      },
      {
        // Breakpoint to use when mediaqueries cannot be used, such as in server-side rendering
        // (Defaults to 'base')
        fallback: "md",
      }
    ) ?? 240;

  return (
    <chakra.section>
      <Container maxW="7xl">
        <Flex
          pt={{ base: 10, md: 28 }}
          pb={{ base: 10 }}
          px={{ base: 2, md: 4 }}
          direction={{ base: "column-reverse", md: "row" }}
        >
          <Stack
            spacing={{ base: 4, md: 10 }}
            w={{ base: "100%", md: "424px" }}
            px={{ base: 0, md: 8 }}
            pt={{ base: 4, md: 16 }}
          >
            <Text
              as="h2"
              fontSize={{ base: "lg", md: "2xl" }}
              fontWeight="bold"
              lineHeight={1.2}
            >
              Najczęstsze dolegliwości, z którymi zgłaszają się pacjenci
            </Text>
            <Button variant="outline" as={Link} to="/trychologia">
              Przejdź do artykułów
            </Button>
          </Stack>
          <Flex overflowX="auto" direction="column">
            <Box mb="4" position="sticky" left="0px">
              <HStack spacing={{ base: 2, md: 4 }}>
                <Heading as="h2" fontSize={{ base: "2xl" }} fontWeight="bold">
                  Twój problem
                </Heading>
                <IconButton
                  aria-label="Back gallery"
                  variant="ghost"
                  icon={<ArrowBackIcon />}
                  onClick={() => slider?.slickPrev()}
                />
                <IconButton
                  aria-label="Next gallery"
                  variant="ghost"
                  icon={<ArrowForwardIcon />}
                  onClick={() => slider?.slickNext()}
                />
              </HStack>
            </Box>
            <CarouselContainer style={{ height: targetCardWidth + 60 }}>
              <Slider
                {...settings}
                ref={(slider) => setSlider(slider)}
                className="slider-container"
              >
                {data?.map((d: any, idx) => {
                  let featuredImg = getImage(
                    d.node.frontmatter.featuredimage?.childImageSharp
                      ?.gatsbyImageData
                  );
                  return (
                    <Link key={d?.node.id} to={d?.node?.fields?.slug || "/"}>
                      <Box
                        style={{
                          width: targetCardWidth,
                          height: targetCardWidth,
                          marginRight: 8,
                        }}
                        w={{ base: "240px", md: "400px" }}
                        h={{ base: "240px", md: "400px" }}
                        minW={{ base: "240px", md: "400px" }}
                        minH={{ base: "240px", md: "400px" }}
                        key={idx}
                        objectFit="cover"
                        bg="brand.100"
                      >
                        {featuredImg && (
                          <GatsbyImage
                            image={featuredImg}
                            alt={d.node.frontmatter.title}
                            objectFit="cover"
                            style={{ height: "100%" }}
                          />
                        )}
                      </Box>
                      <Text
                        as="h3"
                        fontSize={{ base: "lg", md: "xl" }}
                        fontWeight="bold"
                        lineHeight={1.2}
                        textAlign="center"
                        mt={4}
                      >
                        {d?.node.frontmatter?.title}
                      </Text>
                    </Link>
                  );
                })}
                <LastSliderCard key={cards.length + 1} />
              </Slider>
            </CarouselContainer>
          </Flex>
        </Flex>
      </Container>
    </chakra.section>
  );
};
