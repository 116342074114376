import React, { Fragment } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Heading,
  Text,
  Flex,
  Stack,
  Divider,
} from "@chakra-ui/react";

type CollapseProps = {
  name: string;
  procedures: {
    name: string;
    description: string;
    price: string;
    time: string;
    subServices?: {
      name: string;
      price: string;
      time: string;
    }[];
  }[];
};

export const Collapse: React.FC<CollapseProps> = ({ name, procedures }) => {
  return (
    <Accordion allowToggle>
      <AccordionItem>
        <AccordionButton
          borderRadius="sm"
          py={4}
          _expanded={{ bg: "brand.500", color: "white" }}
        >
          <Box as="span" flex="1" textAlign="left">
            <Heading as="h2" fontSize="xl">
              {name}
            </Heading>
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel>
          {procedures.map((procedure, index) => (
            <Fragment key={procedure.name}>
              <Flex>
                <Box flex={1}>
                  <Heading as="h4" fontSize="lg" my={4}>
                    {procedure.name}
                  </Heading>
                  <Text lineHeight={1.2} fontWeight="bold">
                    {procedure.description}
                  </Text>
                </Box>
                <Flex
                  w="100px"
                  flexDirection="column"
                  justifyContent="center"
                  align="center"
                >
                  <Stack gap={0} textAlign="center" my={4}>
                    <Text>
                      <strong>{procedure.price}</strong>
                    </Text>
                    <Text color="gray.400">{procedure.time}</Text>
                  </Stack>
                </Flex>
              </Flex>
              <Box ml={4}>
                {procedure?.subServices?.map((subService, index) => (
                  <Fragment key={index}>
                    <Flex mt={4}>
                      <Box flex={1}>
                        <Heading as="h5" fontSize="md" my={4}>
                          {subService.name}
                        </Heading>
                      </Box>
                      <Flex
                        w="100px"
                        flexDirection="column"
                        justifyContent="center"
                        align="center"
                      >
                        <Stack gap={0} textAlign="center" my={2}>
                          <Text>
                            <strong>{subService.price}</strong>
                          </Text>
                          <Text color="gray.400">{subService.time}</Text>
                        </Stack>
                      </Flex>
                    </Flex>
                    {procedure?.subServices &&
                      procedure.subServices.length !== index + 1 && (
                        <Divider mt={4} />
                      )}
                  </Fragment>
                ))}
              </Box>
              {procedures.length !== index + 1 && <Divider mt={4} />}
            </Fragment>
          ))}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
