import { Icon, IconProps } from "@chakra-ui/react";
import * as React from "react";

export const FaceIcon = (props: IconProps) => (
  <Icon x="0px" y="0px" viewBox="0 0 81.64 122.88" {...props}>
    <path
      color="currentColor"
      stroke="currentColor"
      fill="currentColor"
      d="M22.88,94.65c-0.44,1.04-0.78,2.13-1.03,3.24c-8.81-10.74-10.73-13.18-11.71-26.95h0.21 c0.58-1.89,0.69-4.94,1.74-5.68c0.34-2.12,0.45-4.08,0.27-5.85c-0.16-1.54-0.53-2.94-1.17-4.16c-0.02-9.05,0.53-16.3,3.04-17.34 c1.5-3.1,3.5-3.87,5.8-3.3c0.44-2.27-0.41-4.88-1.56-7.78c1.91,0.87,3.77,3.83,4.53,7.86c5.14-1.26,11.62-1.27,19.49,0 c1.5,1.28,3.19,1.77,5.01,1.68c2.75-0.11,5.02-1.13,7.41-1.06c1.33,0.04,2.63,1.07,4.1,1.23c0.77,0.08,1.55,0.13,2.32,0.14 c3.82,0.06,7.39-0.69,10.8-2.45c13.8-7.1,7.03-24.51-5.61-27.77C58.91,4.5,49.39,5.63,41.19,2.77C39.03,2.02,36.73,1.1,34.27,0 l4.45,5.74C31.4,3.29,27.83,2.19,22.67,5.32c3.23-0.42,5.75-0.22,6.59,0.24c-4.13,1.06-8.12,2.94-10.7,5.5 c3.34-0.1,7.18,0.96,9.81,2.89c-6.37,0-11.14-1.1-15.54-0.64c-2.48,0.26-9.31,1.78-5.83,5.35c0.72,0.74,1.81,0.96,3.12,0.82 c0.23,1.61-3.09,1.99-4.63,3.04c-2.57,1.76-5.65,4.79-5.5,8.15c0.07,1.57,0.91,2.96,2.44,4.18C0,39.09,0.73,44.54,4.05,50.96 l2.68,5.36l1.61,14.62H8.4c0.2,18.69,2.49,48.47,27.33,51.79c2.1,0.28,4.77,0.14,7.97-0.39c16.14,4.04,33.03-16.18,34.37-30.46 c0.64-6.36,0.91-12.73,0.58-19.1l-0.64-2.27c0.08,0.16,0.15,0.31,0.24,0.43c0-3.75-0.15-5.8,0.65-9.46 c0.99-4.54-0.26-8.77,0.91-12.32c1.02-3.11,2.92-3.64,1.03-8.21c-1.69-4.59-4.83-5.81-9.28-3.99c-0.12,0.25,0.01,0.74,0.41,1.2 c2.02,2.37,1.15,3.45,2.1,6.8c0.38,1.32,0.97,2.49,1.44,4.25c1.2,4.54,0.99,8.05,1.02,12.68c0.24,2.56,0.52,4.9,0.89,6.62 C76.57,82.02,74,87.04,65.62,97.75c-2.54-10.08-19.74-13.61-21.82-9.82C40.46,83.58,25.12,89.38,22.88,94.65L22.88,94.65 L22.88,94.65z M29.5,101.31c0.33-2.25,1.31-3.7,2.26-5.09c1.84-2.69,3-2.51,5.68-2.09c4.79,0.75,9.55,0.6,14.27-0.43 c3.08-0.05,6.24,5.35,5.93,7.85c-0.3,2.36-4.82,5.18-7.24,5.17c-2.16-0.01-4.43-1.17-6.82-3.52 C38.75,108.16,33.79,107.61,29.5,101.31L29.5,101.31L29.5,101.31z"
    />
  </Icon>
);
