import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const VaccineIcon = (props: IconProps) => (
  <Icon width="800px" height="800px" viewBox="-0.1 0 49.244 49.244" {...props}>
    <g
      id="Group_128"
      data-name="Group 128"
      transform="translate(-377.111 -79.084)"
    >
      <g id="Group_127" data-name="Group 127">
        <path
          id="Path_470"
          data-name="Path 470"
          d="M408.554,91.947l4.062-4.062,4.937,4.937-4.062,4.062"
          fill="none"
          stroke="currentColor"
          stroke-miterlimit="10"
          stroke-width="2.592"
        />
      </g>
      <path
        id="Path_471"
        data-name="Path 471"
        d="M395.745,114.8l-4.129,4.13a.876.876,0,0,1-1.237,0l-3.871-3.871a.875.875,0,0,1,0-1.236l4.13-4.13"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="2.592"
      />
      <line
        id="Line_79"
        data-name="Line 79"
        x1="9.164"
        y2="9.164"
        transform="translate(378.944 117.331)"
        fill="none"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2.592"
      />
      <path
        id="Path_472"
        data-name="Path 472"
        d="M406.617,90.01l8.811,8.811a1.99,1.99,0,0,1,0,2.813L400.272,116.79a1.988,1.988,0,0,1-2.813,0l-8.811-8.81a1.99,1.99,0,0,1,0-2.814L403.8,90.01A1.989,1.989,0,0,1,406.617,90.01Z"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="2.592"
      />
      <path
        id="Path_473"
        data-name="Path 473"
        d="M413.907,81.162l10.37,10.37a1.989,1.989,0,0,1,0,2.813l-1.261,1.261a1.988,1.988,0,0,1-2.813,0l-10.37-10.37a1.989,1.989,0,0,1,0-2.813l1.261-1.26A1.989,1.989,0,0,1,413.907,81.162Z"
        fill="none"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="2.592"
      />
    </g>
  </Icon>
);
