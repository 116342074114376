import React from "react";
import { Icon, IconProps } from "@chakra-ui/react";

export const HairLossIcon = (props: IconProps) => (
  <Icon
    width="512.000000pt"
    height="512.000000pt"
    viewBox="0 0 512.000000 512.000000"
    {...props}
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M4637 4516 c-130 -122 -344 -343 -523 -541 -640 -709 -1066 -1383
-1195 -1894 -54 -211 -49 -361 15 -477 96 -174 336 -218 451 -83 91 109 118
264 71 418 -42 136 -32 231 49 476 188 572 649 1300 1258 1984 129 146 139
170 92 216 -48 49 -71 38 -218 -99z m-1033 -1467 c-31 -55 -81 -158 -113 -227
-182 -396 -246 -700 -192 -911 25 -101 26 -152 5 -214 -36 -105 -126 -126
-207 -49 -152 145 17 689 390 1262 83 127 162 240 168 240 2 0 -21 -45 -51
-101z"
      />
      <path
        d="M2347 4298 c-13 -13 -72 -106 -133 -208 -548 -915 -889 -1746 -991
-2410 -24 -160 -24 -458 0 -555 25 -97 58 -166 107 -219 116 -125 316 -140
424 -32 52 51 75 113 83 216 7 96 -10 159 -72 260 -59 99 -75 153 -82 285 -28
546 233 1453 678 2351 65 133 119 251 119 263 0 12 -11 33 -25 46 -31 32 -78
33 -108 3z m-737 -1882 c-64 -288 -97 -591 -87 -791 9 -172 32 -248 114 -371
26 -40 36 -66 40 -111 14 -151 -62 -219 -184 -164 -77 36 -119 140 -129 326
-17 288 67 699 237 1170 17 47 33 84 35 82 2 -2 -10 -65 -26 -141z"
      />
      <path
        d="M4441 3395 c-214 -37 -247 -45 -263 -65 -26 -32 -23 -76 8 -107 27
-27 37 -27 169 -7 l40 7 -46 -49 c-244 -261 -392 -571 -425 -896 -6 -62 -5
-68 19 -92 34 -34 79 -34 112 -1 14 13 25 35 25 48 0 54 33 208 65 304 67 199
164 360 314 515 52 55 97 96 98 91 2 -5 -15 -62 -37 -128 -22 -65 -40 -130
-40 -142 0 -34 43 -73 78 -73 56 0 68 21 157 290 47 140 85 265 85 278 0 35
-43 72 -82 71 -18 -1 -143 -20 -277 -44z"
      />
      <path
        d="M660 1820 c-187 -43 -280 -60 -325 -60 -86 0 -126 -80 -69 -137 25
-25 29 -26 109 -20 45 3 143 20 216 38 74 18 162 33 196 33 55 1 66 -2 89 -26
l27 -27 -13 -135 c-30 -309 20 -542 155 -731 225 -316 672 -367 934 -106 126
125 175 257 175 471 0 119 -3 144 -27 215 -14 44 -47 115 -73 158 -55 93 -59
130 -18 165 26 23 31 23 123 15 106 -9 206 -10 353 -3 95 5 98 4 125 -22 l27
-28 -12 -108 c-29 -259 2 -473 94 -662 39 -77 62 -109 128 -175 137 -136 272
-191 466 -189 128 1 198 21 306 85 175 105 285 340 271 579 -7 117 -33 206
-98 328 -56 105 -61 141 -24 177 30 31 46 31 226 0 229 -40 382 -55 562 -55
165 0 168 0 192 25 33 32 33 78 0 110 -23 24 -28 25 -178 25 -189 0 -309 11
-537 51 -238 42 -306 33 -386 -49 -47 -49 -67 -107 -62 -185 3 -53 11 -76 50
-142 63 -109 89 -191 95 -300 16 -282 -167 -494 -427 -495 -248 0 -433 165
-501 445 -24 101 -31 291 -15 450 14 152 -19 216 -140 276 l-65 32 -137 -12
c-103 -9 -169 -9 -266 0 -183 15 -254 -7 -318 -102 -59 -88 -53 -178 20 -305
78 -135 85 -161 86 -299 1 -145 -21 -230 -83 -317 -146 -204 -477 -224 -672
-41 -96 90 -159 224 -185 396 -16 100 -15 182 2 394 8 100 -9 156 -62 202 -88
78 -185 95 -334 61z"
      />
    </g>
  </Icon>
);
